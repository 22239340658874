import React from 'react';

const PagefilesImport0 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/dialogs/network/insights/clients/ClientBlockDialog.page.tsx"));
const PagefilesImport1 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/dialogs/network/insights/events/MutationDiffAuditLogSummary.page.tsx"));
const PagefilesImport2 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/dialogs/network/insights/host_monitoring/HostMonitoringTargetDelete.page.tsx"));
const PagefilesImport3 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/dialogs/network/tools/quick_access/QuickAccess.page.tsx"));

const routes = [
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddress/block",element: React.createElement(PagefilesImport0)},
{path: "/org/:companyName/network/:networkSlug/insights/host-monitoring/:targetUUID/delete",element: React.createElement(PagefilesImport2)},
{path: "/org/:companyName/network/:networkSlug/logs/mutation-audit-log/:mutationAuditLogEntryUUID",element: React.createElement(PagefilesImport1)},
{path: "/org/:companyName/network/:networkSlug/tools/quick-access",element: React.createElement(PagefilesImport3)},
];

export default routes;