import type { CSS } from '../../stitches.config';
import type { SpacingScale } from './sizes';
import { sizing } from '../../common/sizing';

export type ContextProp = 'application' | 'content' | 'section';
export type GutterSpacing = SpacingScale;
export type GutterProp =
  | 'all'
  | 'bottom'
  | 'horizontal'
  | 'left'
  | 'none'
  | 'right'
  | 'top'
  | 'vertical';

export type GutterProps = {
  context?: ContextProp;
  gutter?: GutterProp;
};

export const gutterCSS = ({ context = 'application', gutter = 'all' }: GutterProps = {}): CSS => {
  if (context === 'content') {
    switch (gutter) {
      case 'all':
        return { padding: sizing.contentSquish };
      case 'vertical':
        return { padding: sizing.contentEndsOnly };
      case 'horizontal':
        return { padding: sizing.contentSidesOnly };
      case 'top':
        return { paddingTop: sizing.contentEnds };
      case 'bottom':
        return { paddingBottom: sizing.contentEnds };
      case 'left':
        return { paddingLeft: sizing.contentSides };
      case 'right':
        return { paddingRight: sizing.contentSides };
      case 'none':
      default:
        return { padding: 0 };
    }
  }

  if (context === 'section') {
    switch (gutter) {
      case 'all':
        return { padding: `$8 ${sizing.sides}` };
      case 'vertical':
        return { padding: '$8 0' };
      case 'horizontal':
        return { padding: sizing.sidesOnly };
      case 'top':
        return { paddingTop: '$8' };
      case 'bottom':
        return { paddingBottom: '$8' };
      case 'left':
        return { paddingLeft: sizing.sides };
      case 'right':
        return { paddingRight: sizing.sides };
      case 'none':
      default:
        return { padding: 0 };
    }
  }

  switch (gutter) {
    case 'all':
      return { padding: sizing.squish };
    case 'vertical':
      return { padding: sizing.endsOnly };
    case 'horizontal':
      return { padding: sizing.sidesOnly };
    case 'top':
      return { paddingTop: sizing.ends };
    case 'bottom':
      return { paddingBottom: sizing.ends };
    case 'left':
      return { paddingLeft: sizing.sides };
    case 'right':
      return { paddingRight: sizing.sides };
    case 'none':
    default:
      return { padding: 0 };
  }
};
