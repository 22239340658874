import { type SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';
import { type NavigateOptions, useLocation, useNavigate } from 'react-router-dom'; // switch to `react-router-dom` if using React Router

/**
 * A stable version of `useSearchParams` from `react-router-dom`.
 *
 * https://github.com/remix-run/react-router/issues/9991#issuecomment-2282208096
 */
export function useSearchParamsStable() {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const searchParamsRef = useRef(searchParams);

  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  const setSearchParams = useCallback(
    (setter: SetStateAction<URLSearchParams>, navigateOpts?: NavigateOptions) => {
      const newParams = typeof setter === 'function' ? setter(searchParamsRef.current) : setter;
      navigate(`?${newParams}`, navigateOpts);
    },
    [navigate],
  );

  return [searchParams, setSearchParams] as const;
}
