import { styled, TerminateButton, TextInput } from '@meterup/atto';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { ROOT_ID } from '../core';
import { useCommand } from './Root';

const InputForm = styled('div', {
  padding: '$12',
});

export const Input = observer(() => {
  const { state } = useCommand();

  const rInput = React.useRef<HTMLLabelElement>(null);

  React.useEffect(() => {
    rInput.current?.focus();
  }, [state.currentRoot]);

  const placeholder =
    (state.currentRoot.id !== ROOT_ID && state.currentRoot.label) || 'Type a command or search…';

  return (
    <InputForm>
      <TextInput
        aria-label="Type a command or search..."
        ref={rInput}
        value={state.ui.search}
        onChange={(val) =>
          state.ui.update((ui) => {
            // eslint-disable-next-line no-param-reassign
            ui.search = val;
          })
        }
        autoFocus
        placeholder={placeholder}
        controlSize="x-large"
        icon="search"
        width="100%"
        hasClear
        type="search"
        suffix={
          <TerminateButton
            onClick={() => state.ui.toggle(false)}
            icon="backspace"
            label="Clear"
            variant="neutral"
          />
        }
      />
    </InputForm>
  );
});
