import type { UseStorageOptions } from './useStorage';
import { getStorage, useStorage } from './useStorage';

export function getSessionStorage<T>(key: string, initialValue: T, options?: UseStorageOptions<T>) {
  return getStorage(window.sessionStorage, key, initialValue, options);
}

export function useSessionStorage<T>(key: string, initialValue: T, options?: UseStorageOptions<T>) {
  return useStorage(window.sessionStorage, key, initialValue, options);
}
