import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

export interface UseStorageOptions {
  poll?: number;
  logError?: (error: any) => void;
}

export function getStorage<T>(
  storage: typeof window.localStorage | typeof window.sessionStorage,
  key: string,
  initialValue: T,
  { logError }: UseStorageOptions = {},
): T {
  try {
    const saved = storage.getItem(key);
    if (saved) {
      return JSON.parse(saved);
    }
  } catch (err) {
    logError?.(err);
  }

  return initialValue;
}

export function useStorage<T>(
  storage: typeof window.localStorage | typeof window.sessionStorage,
  key: string,
  initialValue: T,
  { poll, logError }: UseStorageOptions = {},
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() =>
    getStorage(storage, key, initialValue, { logError }),
  );

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        setStoredValue((prev) => {
          if (prev !== valueToStore) {
            storage.setItem(key, JSON.stringify(valueToStore));
          }

          return valueToStore;
        });
      } catch (error) {
        logError?.(error);
      }
    },
    [key, storedValue, logError, storage],
  );

  useEffect(() => {
    if (!poll) {
      return undefined;
    }

    const pollingFn = () => {
      const item = storage.getItem(key);
      const parsedItem = item ? JSON.parse(item) : null;

      if (parsedItem === null) {
        return;
      }

      if (JSON.stringify(storedValue) !== JSON.stringify(parsedItem)) {
        setStoredValue(parsedItem);
      }
    };

    const handle = setInterval(pollingFn, poll);

    return () => {
      clearTimeout(handle);
    };
  }, [poll, storedValue, key, storage]);

  return [storedValue, setValue];
}
