export enum SwitchesTab {
  Current = 'list',
  Spares = 'spares',
  ConfigHistory = 'config-history',
}

export enum SwitchTab {
  BootHistory = 'boot-history',
  ConfigHistory = 'config-history',
  Insights = 'insights',
  MACTable = 'mac-table',
  Ports = 'ports',
}

export enum SwitchEditTab {
  Config = 'config',
  Hardware = 'hardware',
}
