import type { TextareaProps, TextInputProps } from '@meterup/atto';
import {
  FieldContainer,
  HStack,
  PrimaryField,
  PrimaryToggleField,
  SecondaryField,
  Select,
  SelectItem,
  Textarea,
  TextInput,
} from '@meterup/atto';
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import { Duration } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';

import { ucfirst } from '../../utils/strings';
import { type DurationObject, DurationUnit } from '../../utils/time';
import { DurationFieldProvider, FieldProvider, NumberFieldProvider } from './FieldProvider';

export function NumberField({
  label,
  name,
  description,
  internal,
  optional,
  placeholder,
  defaultValue = null,
  disabled = false,
}: {
  label: React.ReactNode;
  name: string;
  description?: React.ReactNode;
  internal?: boolean;
  optional?: boolean;
  placeholder?: string;
  defaultValue?: number | null;
  disabled?: boolean;
}) {
  return (
    <FieldContainer>
      <NumberFieldProvider name={name} defaultValue={defaultValue}>
        <PrimaryField
          internal={internal}
          label={label}
          optional={optional}
          element={<TextInput type="number" placeholder={placeholder} disabled={disabled} />}
          description={description}
        />
      </NumberFieldProvider>
    </FieldContainer>
  );
}

export function TextField({
  label,
  name,
  description,
  internal,
  optional,
  controls,
  ...elementProps
}: TextInputProps & {
  label: React.ReactNode;
  name: string;
  description?: React.ReactNode;
  internal?: boolean;
  optional?: boolean;
  controls?: React.ReactNode;
}) {
  return (
    <FieldContainer>
      <FieldProvider name={name}>
        <PrimaryField
          internal={internal}
          label={label}
          optional={optional}
          controls={controls}
          element={<TextInput {...elementProps} />}
          description={description}
        />
      </FieldProvider>
    </FieldContainer>
  );
}

export function TextareaField({
  label,
  name,
  description,
  internal,
  optional,
  ...elementProps
}: TextareaProps & {
  label: React.ReactNode;
  name: string;
  description?: React.ReactNode;
  internal?: boolean;
  optional?: boolean;
  rows?: number;
}) {
  return (
    <FieldContainer>
      <FieldProvider name={name}>
        <PrimaryField
          internal={internal}
          label={label}
          optional={optional}
          element={<Textarea {...elementProps} />}
          description={description}
        />
      </FieldProvider>
    </FieldContainer>
  );
}

export function PercentField({
  name,
  label,
  internal,
  indeterminate,
}: {
  name: string;
  label: React.ReactNode;
  internal?: boolean;
  indeterminate?: boolean;
}) {
  return (
    <FieldProvider name={name}>
      <NumberFieldProvider name={name} defaultValue={0}>
        <SecondaryField
          label={label}
          internal={internal}
          element={
            <Select width="100%" indeterminate={indeterminate}>
              {[...Array(101).keys()].map((p) => (
                <SelectItem key={p.toString()} textValue={`${p.toString()} %`}>
                  {p.toString()} %
                </SelectItem>
              ))}
            </Select>
          }
        />
      </NumberFieldProvider>
    </FieldProvider>
  );
}

export function ToggleField({
  name,
  label,
  description,
  internal,
  supportsIndeterminate,
  disabled,
}: {
  name: string;
  label: React.ReactNode;
  description?: React.ReactNode;
  internal?: boolean;
  supportsIndeterminate?: boolean;
  disabled?: boolean;
}) {
  return (
    <FieldProvider name={name}>
      <PrimaryToggleField
        internal={internal}
        label={label}
        supportsIndeterminate={supportsIndeterminate}
        description={description}
        disabled={disabled}
      />
    </FieldProvider>
  );
}

/**
 * Used for a luxon Duration object value. Note that this provides its own FieldProvider, so don't
 * wrap it in one.
 */
export function DurationField({
  label,
  name,
  description,
  internal,
  optional,
  placeholder,
  disabled = false,
  availableUnits,
}: {
  label: React.ReactNode;
  name: string;
  description?: React.ReactNode;
  internal?: boolean;
  optional?: boolean;
  placeholder?: string;
  disabled?: boolean;
  availableUnits?: Partial<Record<DurationUnit, boolean>>;
}) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const nameValue: DurationObject = useMemo(() => get(values, name, {}), [values, name]);

  const [unit, setUnit] = useState<DurationUnit>(
    () =>
      (availableUnits
        ? (Object.keys(availableUnits) as DurationUnit[])
        : Object.values(DurationUnit)
      ).findLast((u) => u in nameValue) ?? DurationUnit.Hours,
  );

  const unitValue = useMemo(() => {
    const duration = Duration.fromObject(nameValue);
    return duration.as(unit);
  }, [nameValue, unit]);

  const handleUnitChange = useCallback(
    (value: string) => {
      if (Object.values(DurationUnit).includes(value as DurationUnit)) {
        setUnit((prev) => {
          const prevUnitValue = get(nameValue, prev, 0);
          setFieldValue(name, { [value]: prevUnitValue });
          setFieldTouched(name, true);
          return value as DurationUnit;
        });
      }
    },
    [setUnit, name, nameValue, setFieldValue, setFieldTouched],
  );

  const handleValueChange = useCallback(
    (value: string) => {
      if (!value) {
        setFieldValue(name, { [unit]: null });
        setFieldTouched(name, true);
      } else {
        const numValue = Number.parseInt(value, 10);
        if (Number.isNaN(numValue) || !Number.isInteger(numValue)) return;

        setFieldValue(name, { [unit]: numValue });
        setFieldTouched(name, true);
      }
    },
    [unit, name, setFieldValue, setFieldTouched],
  );

  const durationOptions = useMemo((): DurationUnit[] => {
    let options = Object.values(DurationUnit);

    if (availableUnits) {
      options = options.filter((o) => availableUnits[o]);
    }

    return options;
  }, [availableUnits]);

  return (
    <DurationFieldProvider name={name}>
      <PrimaryField
        internal={internal}
        label={label}
        optional={optional}
        description={description}
        element={
          <HStack spacing={8}>
            <TextInput
              type="text"
              inputMode="numeric"
              pattern="\d*"
              placeholder={placeholder}
              disabled={disabled}
              value={unitValue?.toString()}
              onChange={handleValueChange}
            />
            <Select width="7rem" value={unit} onValueChange={handleUnitChange}>
              {durationOptions.map((dUnit) => (
                <SelectItem key={dUnit} textValue={ucfirst(dUnit)}>
                  {ucfirst(dUnit)}
                </SelectItem>
              ))}
            </Select>
          </HStack>
        }
      />
    </DurationFieldProvider>
  );
}
