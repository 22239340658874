import type { BadgeVariant } from '@meterup/atto';
import { Badge, HStack, Text } from '@meterup/atto';
import { ScheduledNosUpgradeStatus } from '@meterup/common/src/gql/graphql';
import { useGraphQL } from '@meterup/graphql';
import { z } from 'zod';

import type {
  NosVersionByIDQueryResult,
  NosVersionQueryResult,
  NosVersionsQueryResult,
} from '../utils';
import { graphql } from '../../../gql';
import {
  type NosUpgradeGroupsForNetworkQuery,
  type ScheduledNetworkNosUpgradesforNetworkQuery,
  NetworkNosUpgradeChannel,
} from '../../../gql/graphql';
import {
  CreateNosUpgradeGroupInputSchema,
  SetNetworkNosVersionInputSchema,
} from '../../../gql/zod-types';

export enum ValidNOSUpgradesTab {
  Groups = 'groups',
  Upgrades = 'upgrades',
}

export type NOSUpgradeGroup = NonNullable<
  NosUpgradeGroupsForNetworkQuery['nosUpgradeGroupsForNetwork']
>[number];

export const nosUpgradeGroupsForNetworkQuery = graphql(`
  query NOSUpgradeGroupsForNetwork($networkUUID: UUID!) {
    nosUpgradeGroupsForNetwork(networkUUID: $networkUUID) {
      UUID
      name
      priority
      updatedAt
      description
      defaultDeviceType
      defaultDeviceSubtype
      networkUUID
      timeoutDurationSeconds
      virtualDevices {
        label
        UUID
        deviceType
        deviceModel
        network {
          slug
          companySlug
        }
        hardwareDevice {
          macAddress
        }
      }
    }
  }
`);

export type ScheduledNOSUpgrade = NonNullable<
  ScheduledNetworkNosUpgradesforNetworkQuery['scheduledNetworkNOSUpgradesForNetwork']
>[number];

export const scheduledNetworkNOSUpgradesForNetworkQuery = graphql(`
  query ScheduledNetworkNOSUpgradesforNetwork($networkUUID: UUID!) {
    scheduledNetworkNOSUpgradesForNetwork(networkUUID: $networkUUID) {
      UUID
      networkUUID
      nosVersion {
        id
        isDefault
        version
        name
        major
        minor
        patch
      }
      scheduledAt
      completedAt
      status
      pendingNOSUpgradeGroups {
        UUID
        name
      }
      groupUpgrades {
        UUID
        nosUpgradeGroup {
          UUID
          name
        }
        scheduledAt
        completedAt
        status

        deviceUpgrades {
          virtualDevice {
            label
          }
          status
          scheduledAt
          completedAt
        }
      }
    }
  }
`);

export const createNOSUpgradeGroupInputSchema = CreateNosUpgradeGroupInputSchema.extend({
  timeoutDurationMinutes: z
    .number()
    .min(0, { message: 'Duration cannot be negative' })
    .max(20, { message: 'Duration cannot be greater than 20 minutes' }),
  timeoutDurationConfirmed: z.boolean(),
}).superRefine(({ timeoutDurationMinutes, timeoutDurationConfirmed }, ctx) => {
  if (!timeoutDurationConfirmed && timeoutDurationMinutes < 3) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Must confirm duration less than 3 minutes',
      path: ['timeoutDurationConfirmed'],
    });
  }
});
export type NOSUpgradeGroupFormValues = z.infer<typeof createNOSUpgradeGroupInputSchema>;

export const createNOSUpgradeGroupMutation = graphql(`
  mutation CreateNOSUpgradeGroup($networkUUID: UUID!, $input: CreateNOSUpgradeGroupInput!) {
    createNOSUpgradeGroup(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateNOSUpgradeGroupMutation = graphql(`
  mutation UpdateNOSUpgradeGroup($uuid: UUID!, $input: UpdateNOSUpgradeGroupInput!) {
    updateNOSUpgradeGroup(nosUpgradeGroupUUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const updateNOSUpgradeGroupsMutation = graphql(`
  mutation UpdateNOSUpgradeGroups($inputs: [UpdateNOSUpgradeGroupsInput!]!) {
    updateNOSUpgradeGroups(inputs: $inputs) {
      UUID
    }
  }
`);

export const deleteNOSUpgradeGroupMutation = graphql(`
  mutation DeleteNOSUpgradeGroup($uuid: UUID!) {
    deleteNOSUpgradeGroup(nosUpgradeGroupUUID: $uuid) {
      UUID
    }
  }
`);

export const cancelNOSUpgradeMutation = graphql(`
  mutation CancelNOSUpgrade($uuid: UUID!, $force: Boolean!) {
    cancelScheduledNetworkNOSUpgrade(
      scheduledNetworkNOSUpgradeUUID: $uuid
      forceInProgressCancel: $force
    ) {
      UUID
    }
  }
`);

export const setNetworkNOSVersionInputSchema = SetNetworkNosVersionInputSchema.extend({
  confirmInactiveCellularUpgrade: z.boolean(),
});
export type NOSUpgradeFormValues = z.infer<typeof setNetworkNOSVersionInputSchema>;

export const setNetworkNOSVersionMutation = graphql(`
  mutation SetNetworkNOSVersion($networkUUID: UUID!, $input: SetNetworkNosVersionInput!) {
    setNetworkNosVersion(networkUUID: $networkUUID, input: $input)
  }
`);

export function useUpgradeDurationForNetwork(networkUUID: string) {
  const allUpgrades = useGraphQL(nosUpgradeGroupsForNetworkQuery, { networkUUID }).data
    ?.nosUpgradeGroupsForNetwork;

  if (!allUpgrades || allUpgrades.length === 0) {
    return -1;
  }

  return allUpgrades
    .filter((g) => g.virtualDevices.length > 0)
    .reduce((acc, curr) => acc + curr.timeoutDurationSeconds, 0);
}

export function canCancelNetworkUpgrade(upgrade: ScheduledNOSUpgrade) {
  return [ScheduledNosUpgradeStatus.InProgress, ScheduledNosUpgradeStatus.Scheduled].includes(
    upgrade.status,
  );
}

export function cancelingNetworkUpgradeRequiresForce(upgrade: ScheduledNOSUpgrade) {
  return upgrade.status === ScheduledNosUpgradeStatus.InProgress;
}

export function upgradeStatusInFinalState(status: ScheduledNosUpgradeStatus) {
  return [
    ScheduledNosUpgradeStatus.Completed,
    ScheduledNosUpgradeStatus.Failed,
    ScheduledNosUpgradeStatus.Cancelled,
  ].includes(status);
}

export function NOSUpgradeStatusBadge({ status }: { status: ScheduledNosUpgradeStatus }) {
  let variant: BadgeVariant = 'neutral';
  let text = 'Scheduled';
  switch (status) {
    case ScheduledNosUpgradeStatus.Failed:
      variant = 'negative';
      text = 'Failed';
      break;
    case ScheduledNosUpgradeStatus.Cancelled:
      variant = 'attention';
      text = 'Canceled';
      break;
    case ScheduledNosUpgradeStatus.Completed:
      variant = 'positive';
      text = 'Completed';
      break;
    case ScheduledNosUpgradeStatus.InProgress:
      variant = 'brand';
      text = 'In Progress';
      break;
  }

  return (
    <Badge variant={variant} size="small" ends="pill">
      {text}
    </Badge>
  );
}

export function NOSVersionStack({
  nosVersion,
  includeDefault = true,
}: {
  nosVersion:
    | NosVersionsQueryResult
    | NosVersionQueryResult
    | NosVersionByIDQueryResult
    | ScheduledNOSUpgrade['nosVersion'];
  includeDefault?: boolean;
}) {
  return (
    <HStack align="center" spacing={6}>
      {nosVersion.version}
      <Text size="12">
        (
        <Text family="monospace">
          {nosVersion.major}.{nosVersion.minor}.{nosVersion.patch}
        </Text>
        )
      </Text>
      {includeDefault && nosVersion.isDefault && (
        <Badge size="small" icon="checkmark" variant="positive" arrangement="leading-label">
          Default
        </Badge>
      )}
    </HStack>
  );
}

export function NOSUpgradeChannelToDescription(channel: NetworkNosUpgradeChannel) {
  switch (channel) {
    case NetworkNosUpgradeChannel.Sandbox:
      return 'Internal networks that do not receive automated upgrades. The network owner is fully responsible for managing their NOS version.';
    case NetworkNosUpgradeChannel.Kittyfood:
      return 'Internal networks that receive early-stage NOS versions that have passed preliminary QA; additional QA is in progress.';
    case NetworkNosUpgradeChannel.Dogfood:
      return 'Internal networks that receive NOS versions which have completed regression testing; additional QA is in progress.';
    case NetworkNosUpgradeChannel.InternalProduction:
      return 'Internal networks that are used by Meter employees in real-world environments, for example, office and warehouse networks. NOS versions are nearing full QA completion and are prepared for broader internal testing.';
    case NetworkNosUpgradeChannel.EarlyAdoption:
      return 'External networks that receive the first customer-ready NOS versions, intended for non-production customer lab environments. Cutting-edge but may have unknown issues.';
    case NetworkNosUpgradeChannel.Beta:
      return 'External networks that receive the first production-facing NOS version. NOS has passed QA and internal validation.';
    case NetworkNosUpgradeChannel.ReleaseCandidate:
      return 'External networks that receive a near-final NOS version that has completed all QA and validation and has been running stably on customer networks for at least 3 weeks.';
    case NetworkNosUpgradeChannel.GeneralAvailability:
      return 'External networks that receive NOS versions which are fully validated, widely deployed, and considered stable. NOS has been running on customer networks for at least 5 weeks, with the majority now using this version.';
    case NetworkNosUpgradeChannel.LimitedUpgrades:
      return 'For critical or special-case external networks requiring delayed upgrades. This option will postpone updates (including bug fixes/features) by up to 2 months. It is also used for customers with active issues to prevent NOS upgrades during investigations or post-incident stabilization.';
  }
  return '';
}

export function NOSUpgradeChannelIsInternal(channel: NetworkNosUpgradeChannel) {
  return [
    NetworkNosUpgradeChannel.Sandbox,
    NetworkNosUpgradeChannel.Kittyfood,
    NetworkNosUpgradeChannel.Dogfood,
    NetworkNosUpgradeChannel.InternalProduction,
  ].includes(channel);
}
