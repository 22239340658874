import { useViewport } from '@meterup/atto';
import { useLocalStorage } from '@meterup/common';
import { type ReactNode, createContext, useContext, useMemo, useState } from 'react';

interface DockContextData {
  minimized: boolean;
  setMinimized: (minimized: boolean) => void;
  dockOpen: boolean;
  setDockOpen: (dockOpen: boolean) => void;
  sidebarOpen: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
}

const DockContext = createContext<DockContextData>({
  minimized: false,
  setMinimized: () => {},
  dockOpen: false,
  setDockOpen: () => {},
  sidebarOpen: false,
  setSidebarOpen: () => {},
});

export function DockProvider({ children }: { children?: ReactNode }) {
  const { breakpoint } = useViewport();
  const [minimized, setMinimized] = useLocalStorage('dashboard.dock.minimized', false);
  const [dockOpen, setDockOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const value = useMemo(() => {
    let minimizedValue;
    if (breakpoint === 'tablet') {
      minimizedValue = false;
    } else if (breakpoint === 'mobile') {
      minimizedValue = false;
    } else {
      minimizedValue = minimized;
    }
    return {
      minimized: minimizedValue,
      setMinimized,
      dockOpen,
      setDockOpen,
      sidebarOpen,
      setSidebarOpen,
    };
  }, [minimized, setMinimized, dockOpen, setDockOpen, sidebarOpen, setSidebarOpen, breakpoint]);

  return <DockContext.Provider value={value}>{children}</DockContext.Provider>;
}

export function useDockContext() {
  return useContext(DockContext);
}
