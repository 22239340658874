import { type BreadcrumbPageProps, type BreadcrumbProps } from '@meterup/atto';
import { matchPath, useNavigate } from 'react-router';

import { HubTab } from '../components/Hub/utils';
import { ReactRouterLink } from '../components/ReactRouterLink';
import { ValidWIDSTab } from '../components/Wireless/WIDS/utils';
import { paths } from '../constants';
import { useNetwork } from '../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { makeDialogLink, makeDrawerLink, makeLink } from './main_and_drawer_navigation';

export function useNavigateBack() {
  const navigate = useNavigate();
  return () => navigate(-1);
}

export function useNavigateHomeForCompany(companyName: string): BreadcrumbPageProps {
  return {
    label: 'Home',
    as: ReactRouterLink,
    path: paths.pages.HubPage,
    to: makeLink(paths.pages.HubPage, {
      companyName,
      tab: HubTab.Cards,
    }),
  };
}

export function useNavigateHome() {
  const companyName = useCurrentCompany();

  return useNavigateHomeForCompany(companyName);
}

export function useHubCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();

  return [
    {
      page: {
        as: ReactRouterLink,
        path: paths.pages.HubPage,
        to: makeLink(paths.pages.HubPage, {
          companyName,
          tab: HubTab.Cards,
        }),
        icon: 'hub',
        label: 'Hub',
      },
    },
  ];
}

export function useConnectCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();

  return [
    {
      page: {
        as: ReactRouterLink,
        path: paths.pages.ConnectPage,
        to: makeLink(paths.pages.ConnectPage, {
          companyName,
        }),
        icon: 'connect',
        label: 'Connect',
      },
    },
  ];
}

function useSettingsCrumb(): BreadcrumbProps {
  const companyName = useCurrentCompany();

  return {
    page: {
      as: ReactRouterLink,
      path: paths.pages.SettingsPage,
      to: makeLink(paths.pages.SettingsPage, {
        companyName,
      }),
      icon: 'cog',
      label: 'Settings',
    },
  };
}

export function useOrgSettingsCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();

  return [
    useSettingsCrumb(),
    {
      page: {
        as: ReactRouterLink,
        path: paths.pages.SettingsOrgPage,
        to: makeLink(paths.pages.SettingsOrgPage, {
          companyName,
        }),
        label: 'Org',
      },
    },
  ];
}

export function useUserSettingsCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();

  return [
    useSettingsCrumb(),
    {
      page: {
        as: ReactRouterLink,
        path: paths.pages.SettingsUserPage,
        to: makeLink(paths.pages.SettingsUserPage, { companyName }),
        label: 'User',
      },
    },
  ];
}

export function useAutoVPNCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();

  return [
    {
      page: {
        as: ReactRouterLink,
        path: paths.pages.AutoVPNPage,
        to: makeLink(paths.pages.AutoVPNPage, {
          companyName,
        }),
        icon: 'auto-vpn',
        label: 'Auto VPN',
      },
    },
  ];
}

export function useOperatorCrumbs(): BreadcrumbProps[] {
  return [
    {
      page: {
        as: ReactRouterLink,
        path: paths.pages.OperatorPage,
        to: makeLink(paths.pages.OperatorPage, {}),
        icon: 'operator',
        label: 'Operator',
      },
    },
  ];
}

function useNetworkCrumbs(): BreadcrumbProps[] {
  const network = useNetwork();

  return [
    {
      page: {
        as: ReactRouterLink,
        path: paths.pages.NetworkRootPage,
        to: makeLink(paths.pages.NetworkRootPage, {
          networkUUID: network.UUID,
        }),
        icon: 'network',
        label: network.label ?? network.slug,
      },
    },
  ];
}

export function useInsightsCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    ...useNetworkCrumbs(),
    {
      page: {
        as: ReactRouterLink,
        path: paths.pages.InsightsPage,
        to: makeLink(paths.pages.InsightsPage, {
          companyName,
          networkSlug: network.slug,
        }),
        label: 'Insights',
      },
    },
  ];
}

export function useHardwareCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    ...useNetworkCrumbs(),
    {
      page: {
        as: ReactRouterLink,
        path: paths.pages.HardwarePage,
        to: makeLink(paths.pages.HardwarePage, {
          companyName,
          networkSlug: network.slug,
        }),
        label: 'Hardware',
      },
    },
  ];
}

export function useNetworkWideCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    ...useNetworkCrumbs(),
    {
      page: {
        label: 'Network-wide',
        as: ReactRouterLink,
        path: paths.pages.NetworkWidePage,
        to: makeLink(paths.pages.NetworkWidePage, {
          companyName,
          networkSlug: network.slug,
        }),
      },
    },
  ];
}

export function useWirelessCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    ...useNetworkCrumbs(),
    {
      page: {
        label: 'Wireless',
        as: ReactRouterLink,
        path: paths.pages.WirelessPage,
        to: makeLink(paths.pages.WirelessPage, {
          companyName,
          networkSlug: network.slug,
        }),
      },
    },
  ];
}

export function useWIDSCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    ...useNetworkCrumbs(),
    {
      page: {
        label: 'Wireless',
        as: ReactRouterLink,
        path: paths.pages.WirelessPage,
        to: makeLink(paths.pages.WirelessPage, {
          companyName,
          networkSlug: network.slug,
        }),
      },
    },
    {
      page: {
        label: 'WIDS',
        as: ReactRouterLink,
        path: paths.pages.WIDSPage,
        to: makeLink(paths.pages.WIDSPage, {
          companyName,
          networkSlug: network.slug,
          tab: ValidWIDSTab.RogueAPs,
        }),
      },
    },
  ];
}

export function useFirewallCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    ...useNetworkCrumbs(),
    {
      page: {
        label: 'Firewall',
        as: ReactRouterLink,
        path: paths.pages.FirewallPage,
        to: makeLink(paths.pages.FirewallPage, {
          companyName,
          networkSlug: network.slug,
        }),
      },
    },
  ];
}

export function useDesignCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    ...useNetworkCrumbs(),
    {
      page: {
        label: 'Design',
        as: ReactRouterLink,
        path: paths.pages.DesignPage,
        to: makeLink(paths.pages.DesignPage, {
          companyName,
          networkSlug: network.slug,
        }),
      },
    },
  ];
}

export function useSecureTunnelsCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    ...useNetworkCrumbs(),
    {
      page: {
        label: 'Secure tunnels',
        as: ReactRouterLink,
        path: paths.pages.SecureTunnelsPage,
        to: makeLink(paths.pages.SecureTunnelsPage, {
          companyName,
          networkSlug: network.slug,
        }),
      },
    },
  ];
}

export function useIPSecTunnelCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    ...useNetworkCrumbs(),
    {
      page: {
        label: 'Secure tunnels',
        as: ReactRouterLink,
        path: paths.pages.SecureTunnelsPage,
        to: makeLink(paths.pages.SecureTunnelsPage, {
          companyName,
          networkSlug: network.slug,
        }),
      },
    },
    {
      page: {
        label: 'IPSec',
        as: ReactRouterLink,
        path: paths.pages.IPSecTunnelsPage,
        to: makeLink(paths.pages.IPSecTunnelsPage, {
          companyName,
          networkSlug: network.slug,
        }),
      },
    },
  ];
}

export function useNetworkOperatorCrumbs(): BreadcrumbProps[] {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    ...useNetworkCrumbs(),
    {
      page: {
        label: 'Operator',
        as: ReactRouterLink,
        path: paths.pages.NetworkUpgradesPage,
        to: makeLink(paths.pages.NetworkUpgradesPage, {
          companyName,
          networkSlug: network.slug,
          tab: 'groups',
        }),
      },
    },
  ];
}

export function useOpenFromDropdown(
  type: 'dialog' | 'drawer' | 'page',
  path: string,
  params: Record<string, string>,
) {
  const navigate = useNavigate();

  if (type === 'dialog') {
    return () => {
      navigate(
        makeDialogLink(window.location, path, {
          ...params,
        }),
      );
    };
  }

  if (type === 'drawer') {
    return () => {
      navigate(
        makeDrawerLink(window.location, path, {
          ...params,
        }),
      );
    };
  }

  return () => {
    navigate(
      makeLink(path, {
        ...params,
      }),
    );
  };
}

export function getPreviousCrumbPath({
  crumbs,
  params,
}: {
  crumbs: BreadcrumbProps[] | null | undefined;
  params: Record<string, string>;
}): string | undefined {
  if (crumbs) {
    for (const crumb of crumbs.toReversed()) {
      if (!crumb?.page?.path || !crumb?.page.to) continue;
      const match = matchPath(
        {
          path: crumb.page.path,
        },
        crumb.page.to,
      );

      const matchParams = match?.params ?? {};

      if (
        Object.values(matchParams).length === Object.values(params).length &&
        Object.keys(params).every((k) => k in matchParams)
      ) {
        return makeLink(crumb.page.path, params);
      }
    }
  }

  return undefined;
}

/**
 * Don't use this in a tight loop, it iterates over all known paths.
 */
export function getNextBestCurrentPathForNetwork({
  companyName,
  networkSlug,
  crumbs,
  currentPathname,
}: {
  companyName: string;
  networkSlug: string;
  crumbs: BreadcrumbProps[] | null | undefined;
  /**
   * If undefined, skips checking for current path.
   */
  currentPathname: string | undefined;
}): string {
  // This is very hacky but maybe okay?
  if (currentPathname) {
    for (const path of Object.values(paths.pages)) {
      const match = matchPath(
        {
          path,
        },
        currentPathname,
      );

      const params = match?.params ?? {};
      const matchedParams = Object.values(params);

      if (!('companyName' in params) || !('networkSlug' in params)) {
        continue;
      }

      if (matchedParams.length === 2) {
        return makeLink(path, {
          companyName,
          networkSlug,
        });
      }

      if (matchedParams.length === 3 && ('tab' in params || 'activeTab' in params)) {
        return makeLink(path, {
          ...params,
          companyName,
          networkSlug,
        });
      }
    }
  }

  if (crumbs) {
    for (const crumb of crumbs.toReversed()) {
      if (!crumb?.page?.path || !crumb?.page.to) continue;
      const match = matchPath(
        {
          path: crumb.page.path,
        },
        crumb.page.to,
      );

      const params = match?.params ?? {};
      const matchedParams = Object.values(params);

      if (!('companyName' in params) || !('networkSlug' in params)) {
        continue;
      }

      if (matchedParams.length === 2) {
        return makeLink(crumb.page.path, {
          companyName,
          networkSlug,
        });
      }

      if (matchedParams.length === 3 && ('tab' in params || 'activeTab' in params)) {
        return makeLink(crumb.page.path, {
          ...params,
          companyName,
          networkSlug,
        });
      }
    }
  }

  return makeLink(paths.pages.IndividualNetworkRootPage, {
    companyName,
    networkSlug,
  });
}
