import { usePrevious } from '@meterup/atto';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import type { MetaData } from '../../vite/plugins/vite-plugin-build-meta-asset';
import { getRealm } from '../utils/realm';

const requestOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

async function fetchMetaJson(): Promise<MetaData | null> {
  const realm = getRealm();
  // We don't need to check meta for local environments
  if (realm === 'local') {
    return null;
  }

  const resp = await fetch('/meta.json', requestOptions);
  if (resp.ok) {
    return (await resp?.json()) ?? {};
  }

  return null;
}

export function useCheckUpdateOnRouteChange() {
  const location = useLocation();
  const metaJson = useQuery<MetaData | null>(['meta'], fetchMetaJson, {
    suspense: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchInterval: 10_000,
  });
  const newBuildDetected = useRef(false);
  const prevBuildHash = usePrevious(metaJson?.data?.buildHash);
  const prevPathname = usePrevious(location?.pathname);
  const { isLoading, data } = metaJson;

  useEffect(() => {
    if (prevBuildHash && data?.buildHash && prevBuildHash !== data?.buildHash) {
      newBuildDetected.current = true;
    }
  }, [prevBuildHash, data?.buildHash]);

  useEffect(() => {
    if (prevPathname && location.pathname !== prevPathname && newBuildDetected.current) {
      window.location.reload();
    }
  }, [location.pathname, prevPathname, isLoading]);
}
