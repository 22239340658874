import type { SpacingScale } from '@meterup/atto';
import type { Action, ActionConfig, Directory, State } from '@meterup/command';
import type { ControllerVersion } from '@meterup/common';
import type { ExtractRouteParams } from '@meterup/react-router-extensions';
import type { NavigateFunction } from 'react-router';
import {
  copyTextToClipboard,
  DropdownMenu,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  DropdownMenuTrigger,
  Small,
  styled,
  useCurrentCrumbs,
  useViewport,
  VStack,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import {
  observer,
  OPERATOR_ACTIONS_GROUP_NAME,
  Priority,
  useCommand,
  useRegisterCommands,
} from '@meterup/command';
import { isDefined } from '@meterup/common';
import { filter, orderBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { MeterLDFlags } from '../../feature_flags';
import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useActiveControllerForNetwork } from '../../hooks/useActiveControllerForNetwork';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useLogoutHandler } from '../../hooks/useLogoutHandler';
import { useNetworkOrNull } from '../../hooks/useNetworkFromPath';
import { useNetworksForCompany } from '../../hooks/useNetworksForCompany';
import { NosFeature } from '../../hooks/useNosFeatures';
import { ProductTypes, useUserProductsAccess } from '../../hooks/useUserProductsAccess';
import { useCurrentCompanyOrDefault } from '../../providers/CurrentCompanyProvider';
import { useDefaultNetwork } from '../../providers/DefaultNetworkProvider';
import { useIdentity } from '../../providers/IdentityDataProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { getNextBestCurrentPathForNetwork } from '../../utils/routing';
import { NIDSTab } from '../Firewall/utils';
import { AccessPointsTab } from '../Hardware/AccessPoints/enums';
import { PDUsTab } from '../Hardware/PowerDistributionUnits/enums';
import { SecurityAppliancesTab } from '../Hardware/SecurityAppliance/enums';
import { SwitchesTab } from '../Hardware/Switches/enums';
import { useGetAccessLevelForUser } from '../HasAccess/useAccessLevelForUser';
import { useNavigateToNetworkCommand } from '../Navigation/useNavigateToNetworkCommand';
import { useNavigateToSerialNumberCommand } from '../Navigation/useNavigateToSerialNumberCommand';
import { useNavigateToVirtualDeviceCommand } from '../Navigation/useNavigateToVirtualDeviceCommand';
import { ValidNOSUpgradesTab } from '../NOS/Upgrades/utils';
import { BugReportTemplate, DebugData } from '../Operator/Workbench/utils/utils';
import { RoutingTab } from '../Routing/utils';
import { ValidWIDSTab } from '../Wireless/WIDS/utils';
import { dockValues } from './shared';
import { Sigil } from './Sigil';
import { Tool } from './Targets/Tool';

type NodeFactory = State['nodeFactory'];
type WithLinkOptions = Omit<ActionConfig, 'onSelect'>;

type NodesBuilderConstructorParams = {
  companyName: string;
  navigate: NavigateFunction;
  nodeFactory: NodeFactory;
  controllerName?: string | null;
  networkSlug?: string | null;
  controllerVersion?: ControllerVersion | null;
  flags?: MeterLDFlags;
};

type NodesType = (Action | Directory)[];
type FlagKeys = keyof MeterLDFlags;

class NodesBuilder {
  private readonly companyName: string;

  private readonly controllerName?: string;

  private readonly networkSlug?: string;

  private readonly navigate: NavigateFunction;

  private readonly nodeFactory: NodeFactory;

  private nodes: NodesType = [];

  private readonly flags: MeterLDFlags;

  constructor(params: NodesBuilderConstructorParams) {
    this.companyName = params.companyName;
    this.controllerName = params.controllerName || undefined;
    this.networkSlug = params.networkSlug || undefined;
    this.navigate = params.navigate;
    this.nodeFactory = params.nodeFactory;
    this.flags = params.flags || {};
  }

  public withLink<Path extends string>(
    path: { companyName: string; networkSlug: string } extends ExtractRouteParams<Path, string>
      ? Path
      : never,
    options: WithLinkOptions,
  ): this;
  public withLink<Path extends string>(
    path: Path,
    options: WithLinkOptions,
    params: Omit<
      ExtractRouteParams<Path, string>,
      'companyName' | 'controllerName' | 'networkSlug'
    >,
  ): this;
  public withLink<Path extends string>(
    path: Path,
    options: WithLinkOptions,
    params: Omit<
      ExtractRouteParams<Path, string>,
      'companyName' | 'controllerName' | 'networkSlug'
    > = {} as any,
  ): this {
    if (this.controllerName || this.networkSlug) {
      const link = makeLink(path, {
        companyName: this.companyName,
        controllerName: this.controllerName,
        networkSlug: this.networkSlug,
        ...params,
      } as unknown as ExtractRouteParams<Path, string>);
      this.nodes.push(
        this.nodeFactory.action({
          ...options,
          onSelect: () => this.navigate(link),
        }),
      );
    }

    return this;
  }

  public withLinkIfHasFlags(
    path: string,
    flags: FlagKeys[],
    options: WithLinkOptions,
    params = {},
  ) {
    const missingFlag = flags.find((flag) => !this.flags[flag]);
    if (!missingFlag) {
      this.withLink(path, options, params);
    }
    return this;
  }

  public withAction(options: ActionConfig) {
    this.nodes.push(this.nodeFactory.action(options));
    return this;
  }

  public withNode(node: Action | Directory) {
    this.nodes.push(node);
    return this;
  }

  public build() {
    return this.nodes;
  }
}

const AccountDropdownInfo = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$8',
  maxWidth: '$400',
  padding: '$4 $8',
});

function AccountInner({ fallback }: { fallback?: boolean }) {
  const { breakpoint } = useViewport();
  const companyName = useCurrentCompanyOrDefault();
  const network = useNetworkOrNull();
  const identity = useIdentity();
  const navigate = useNavigate();
  const flags = useFeatureFlags();
  const isOperator = useIsOperator();
  const namesAreDefined = identity?.first_name && identity?.last_name;
  const getAccessLevel = useGetAccessLevelForUser();

  const logout = useLogoutHandler();
  const { defaultNetwork } = useDefaultNetwork();
  const access = useUserProductsAccess();
  const isNetworkCustomer = access.isCustomerOfProduct(ProductTypes.NETWORK);

  const changeCurrentCompany = useCallback(
    (value: string) => {
      navigate(makeLink(paths.pages.CompanyRootPage, { companyName: value }));
    },
    [navigate],
  );

  const { crumbs } = useCurrentCrumbs();

  const sortedCompanySlugs = orderBy(identity.company_memberships, (c) => c.company_slug).map(
    (c) => c.company_slug,
  );

  const sortedCompanySlugsIgnoreCurrent = filter(
    sortedCompanySlugs,
    (slug) => slug !== companyName,
  );

  const companyNetworks = useNetworksForCompany(companyName);

  const activeNetworkController = useActiveControllerForNetwork(network);
  const initialSerialNumber =
    activeNetworkController?.hardwareDevice?.serialNumber ??
    network?.virtualDevices[0]?.hardwareDevice?.serialNumber;

  const bugReportTemplate = BugReportTemplate();
  const debugData = DebugData();

  const { state } = useCommand();

  const nodes = useMemo(() => {
    const builder = new NodesBuilder({
      companyName,
      networkSlug: network?.slug ?? defaultNetwork?.slug,
      navigate,
      nodeFactory: state.nodeFactory,
      flags,
    });

    if (isOperator) {
      if (network?.UUID) {
        builder.withAction({
          id: 'copy-network-uuid',
          group: OPERATOR_ACTIONS_GROUP_NAME,
          display: 'Copy current network UUID to clipboard',
          label: 'Copy current network UUID to clipboard',
          icon: 'wrench',
          internal: true,
          onSelect() {
            copyTextToClipboard(network.UUID);
          },
        });
      }
      if (network) {
        builder.withAction({
          id: 'file-bug-report-linear',
          group: OPERATOR_ACTIONS_GROUP_NAME,
          display: 'File a bug report in Linear',
          label: 'File a bug report in Linear',
          icon: 'bug',
          internal: true,
          onSelect() {
            window.open(bugReportTemplate, '_blank');
          },
        });
        builder.withAction({
          id: 'copy-debug-data',
          group: OPERATOR_ACTIONS_GROUP_NAME,
          display: 'Copy debug data to clipboard',
          label: 'Copy debug data to clipboard',
          icon: 'copy',
          internal: true,
          onSelect() {
            copyTextToClipboard(debugData);
          },
        });
      }
      if (network?.slug && initialSerialNumber) {
        builder.withLink(
          paths.pages.DeviceConfigOverridesPage,
          {
            id: 'nav-device-config-editor',
            group: OPERATOR_ACTIONS_GROUP_NAME,
            display: 'Device config',
            label: 'Device config',
            icon: 'code',
            internal: true,
          },
          { serialNumber: initialSerialNumber },
        );
      }
      if (network?.slug) {
        builder.withLink(
          paths.pages.NetworkUpgradesPage,
          {
            id: 'nav-network-upgrades',
            group: OPERATOR_ACTIONS_GROUP_NAME,
            display: 'Network upgrades',
            label: 'Network upgrades',
            icon: 'network-wide',
            internal: true,
          },
          {
            tab: ValidNOSUpgradesTab.Groups,
          },
        );
      }
    }

    if (isNetworkCustomer) {
      // Insights
      if (getAccessLevel({ permissions: PermissionType.PermNetworkMetricsRead })) {
        builder.withLink(paths.pages.InsightsNetworkPage, {
          id: 'nav-insights-network',
          group: 'Insights',
          display: 'Network',
          label: 'Network',
          icon: 'network-wide',
        });
      }
      if (getAccessLevel({ permissions: PermissionType.PermNetworkDevicesRead })) {
        builder.withLink(paths.pages.ClientsPage, {
          id: 'nav-insights-clients',
          group: 'Insights',
          display: 'Clients',
          label: 'Clients',
          icon: 'client',
        });
      }
      if (
        getAccessLevel({
          nosFeatures: [NosFeature.HOST_MONITORING],
          featureFlags: 'host-monitoring',
        })
      ) {
        builder.withLink(paths.pages.HostMonitoringPage, {
          id: 'nav-insights-host-monitoring',
          group: 'Insights',
          display: 'Host monitoring',
          label: 'Host monitoring',
          icon: 'host-monitoring',
        });
      }
      if (getAccessLevel({ permissions: PermissionType.PermNetworkDevicesRead })) {
        builder.withLink(paths.pages.TopologyPage, {
          id: 'nav-insights-topology',
          group: 'Insights',
          display: 'Topology',
          label: 'Topology',
          icon: 'topology',
        });
      }
      builder.withLink(paths.pages.EventLogPage, {
        id: 'nav-insights-logs',
        group: 'Insights',
        display: 'Logs',
        label: 'Logs',
        icon: 'log',
      });

      // Hardware
      if (
        getAccessLevel({
          permissions: PermissionType.PermNetworkDevicesRead,
          nosFeatures: [NosFeature.POS],
          featureFlags: 'power-distribution-units',
        })
      ) {
        builder.withLink(
          paths.pages.PowerDistributionUnitListPage,
          {
            id: 'nav-hardware-power-distribution-units',
            group: 'Hardware',
            display: 'Power distribution units',
            label: 'Power distribution units',
            icon: 'power-distribution-unit',
          },
          {
            tab: PDUsTab.Current,
          },
        );
      }
      if (getAccessLevel({ permissions: PermissionType.PermNetworkDevicesRead })) {
        builder
          .withLink(
            paths.pages.SecurityAppliancesListPage,
            {
              id: 'nav-hardware-security-appliances',
              group: 'Hardware',
              display: 'Security appliances',
              label: 'Security appliances',
              icon: 'security-appliance',
            },
            {
              tab: SecurityAppliancesTab.Current,
            },
          )
          .withLink(
            paths.pages.SwitchesPage,
            {
              id: 'nav-hardware-switches',
              group: 'Hardware',
              display: 'Switches',
              label: 'Switches',
              icon: 'switch',
            },
            {
              tab: SwitchesTab.Current,
            },
          )
          .withLink(
            paths.pages.AccessPointsListPage,
            {
              id: 'nav-hardware-access-points',
              group: 'Hardware',
              display: 'Access points',
              label: 'Access points',
              icon: 'access-point',
            },
            {
              tab: AccessPointsTab.Current,
            },
          );
      }
      if (
        getAccessLevel({
          permissions: PermissionType.PermNetworkDevicesRead,
          featureFlags: 'dashboard-observers',
        })
      ) {
        builder.withLink(paths.pages.ObserversListPage, {
          id: 'nav-hardware-observers',
          group: 'Hardware',
          display: 'Observers',
          label: 'Observers',
          icon: 'observe',
        });
      }

      // Network-wide
      if (getAccessLevel({ permissions: PermissionType.PermIspRead })) {
        builder.withLink(paths.pages.ISPsPage, {
          id: 'nav-network-wide-isps',
          group: 'Network-wide',
          display: 'ISPs',
          label: 'ISPs',
          icon: 'globe',
        });
      }

      if (getAccessLevel({ permissions: PermissionType.PermVlanRead })) {
        builder.withLink(paths.pages.VLANListPage, {
          id: 'nav-network-wide-vlans',
          group: 'Network-wide',
          display: 'VLANs',
          label: 'VLANs',
          icon: 'vlan',
        });
      }

      if (
        getAccessLevel({
          permissions: PermissionType.PermPolicyRoutingRead,
          nosFeatures: [NosFeature.POLICY_ROUTING],
        })
      ) {
        builder.withLink(
          paths.pages.RoutingPage,
          {
            id: 'nav-network-wide-routing',
            group: 'Network-wide',
            display: 'Routing',
            label: 'Routing',
            icon: 'routing',
          },
          {
            tab: RoutingTab.RoutingTable,
          },
        );
      }
      if (
        getAccessLevel({
          permissions: PermissionType.PermRateLimitRead,
          nosFeatures: [NosFeature.RATE_LIMITING],
        })
      ) {
        builder.withLink(paths.pages.RateLimitingPage, {
          id: 'nav-network-wide-rate-limiting',
          group: 'Network-wide',
          display: 'Rate limiting',
          label: 'Rate limiting',
          icon: 'rate-limit',
        });
      }
      if (getAccessLevel({ permissions: PermissionType.PermNetworkSettingsRead })) {
        builder.withLink(paths.pages.RADIUSProfilesPage, {
          id: 'nav-network-wide-radius-profiles',
          group: 'Network-wide',
          display: 'RADIUS profiles',
          label: 'RADIUS profiles',
          icon: 'radius',
        });
      }
      if (
        getAccessLevel({
          permissions: PermissionType.PermCaptivePortalRead,
          nosFeatures: [NosFeature.CAPTIVE_PORTAL],
        })
      ) {
        builder.withLink(
          paths.pages.CaptivePortalPage,
          {
            id: 'nav-network-wide-captive-portal',
            group: 'Network-wide',
            display: 'Captive portal',
            label: 'Captive portal',
            icon: 'captive-portal',
          },
          {
            tab: getAccessLevel({ permissions: PermissionType.PermCaptivePortalWrite })
              ? 'configure'
              : 'activity',
          },
        );
      }

      // Wireless
      if (getAccessLevel({ permissions: PermissionType.PermSsidsRead })) {
        builder.withLink(paths.pages.SSIDsPage, {
          id: 'nav-wireless-ssids',
          group: 'Wireless',
          display: 'SSIDs',
          label: 'SSIDs',
          icon: 'ssid',
        });
      }
      if (getAccessLevel({ permissions: PermissionType.PermRadioProfileRead })) {
        builder.withLink(paths.pages.RadioProfilesPage, {
          id: 'nav-wireless-radio-profiles',
          group: 'Wireless',
          display: 'Radio profiles',
          label: 'Radio profiles',
          icon: 'radio-profile',
        });
      }
      if (
        getAccessLevel({
          featureFlags: 'hotspot-20',
          nosFeatures: [NosFeature.WOS_HOTSPOT_20],
        })
      ) {
        builder.withLink(paths.pages.Hotspot20sPage, {
          id: 'nav-wireless-hotspot20s',
          group: 'Wireless',
          display: 'Hotspot 2.0',
          label: 'Hotspot 2.0',
          icon: 'hotspot-2.0',
        });
      }
      if (
        getAccessLevel({
          permissions: PermissionType.PermNetworkMetricsRead,
          nosFeatures: [NosFeature.WOS_HONEYPOTS],
          featureFlags: ['dashboard-security-view'],
        })
      ) {
        builder.withLink(
          paths.pages.WIDSPage,
          {
            id: 'nav-wireless-wids',
            group: 'Wireless',
            display: 'WIDS',
            label: 'WIDS',
            icon: 'wireless-ids',
          },
          {
            tab: ValidWIDSTab.RogueAPs,
          },
        );
      }

      // Firewall
      if (
        getAccessLevel({
          permissions: PermissionType.PermFirewallRead,
          nosFeatures: [NosFeature.FIREWALL_RULES],
        })
      ) {
        builder.withLink(paths.pages.RulesPage, {
          id: 'nav-firewall',
          group: 'Firewall',
          display: 'ACLs',
          label: 'ACLs',
          icon: 'traffic-light',
        });
      }
      if (
        getAccessLevel({
          permissions: PermissionType.PermFirewallRead,
          nosFeatures: [NosFeature.DNS_SECURITY],
        })
      ) {
        builder.withLink(paths.pages.DNSSecurityPage, {
          id: 'nav-firewall-dns-security',
          group: 'Firewall',
          display: 'DNS security',
          label: 'DNS security',
          icon: 'dns-security',
        });
      }
      if (getAccessLevel({ permissions: PermissionType.PermPortForwardRead })) {
        builder.withLink(paths.pages.PortForwardingPage, {
          id: 'nav-firewall-port-forwarding',
          group: 'Firewall',
          display: 'Port forwarding',
          label: 'Port forwarding',
          icon: 'port-forward',
        });
      }
      if (
        getAccessLevel({
          permissions: PermissionType.PermOneToOneNatRead,
          nosFeatures: [NosFeature.ONE_TO_ONE_NAT],
        })
      ) {
        builder.withLink(paths.pages.OneToOneNATPage, {
          id: 'nav-firewall-one-to-one-nat',
          group: 'Firewall',
          display: 'One to one NAT',
          label: 'One to one NAT',
          icon: 'nat',
        });
      }
      if (getAccessLevel({ permissions: PermissionType.PermFirewallRead })) {
        builder.withLink(paths.pages.MulticastPage, {
          id: 'nav-firewall-multicast',
          group: 'Firewall',
          display: 'mDNS',
          label: 'mDNS',
          icon: 'multicast-dns',
        });
      }
      if (
        getAccessLevel({
          permissions: PermissionType.PermIdsIpsRead,
          nosFeatures: [NosFeature.COS_IDS],
          featureFlags: 'firewall-ids',
        })
      ) {
        builder.withLink(
          paths.pages.NIDSPage,
          {
            id: 'nav-firewall-nids',
            group: 'Firewall',
            display: 'NIDS',
            label: 'NIDS',
            icon: 'network-ids',
          },
          {
            tab: NIDSTab.IDS,
          },
        );
      }

      // Design
      if (getAccessLevel({ permissions: PermissionType.PermRackElevationRead })) {
        builder.withLink(paths.pages.RackElevationsPage, {
          id: 'nav-design-rack-elevations',
          group: 'Design',
          display: 'Rack elevations',
          label: 'Rack elevations',
          icon: 'rack',
        });
      }
      if (getAccessLevel({ permissions: PermissionType.PermCompanyGet })) {
        builder.withLink(paths.pages.FloorPlansPage, {
          id: 'nav-design-floor-plans',
          group: 'Design',
          display: 'Floor plans',
          label: 'Floor plans',
          icon: 'floor-plan',
        });
      }

      // Secure tunnels
      if (getAccessLevel({ permissions: PermissionType.PermClientVpnRead })) {
        builder.withLink(paths.pages.ClientVPNPage, {
          id: 'nav-secure-tunnels-client-vpn',
          group: 'Secure tunnels',
          display: 'Client VPN',
          label: 'Client VPN',
          icon: 'secure',
        });
      }
      if (
        getAccessLevel({
          permissions: PermissionType.PermIpsecTunnelRead,
          nosFeatures: [NosFeature.IPSEC_TUNNELS],
        })
      ) {
        builder.withLink(paths.pages.IPSecTunnelsPage, {
          id: 'nav-secure-tunnels-ipsec-tunnels',
          group: 'Secure tunnels',
          display: 'IPSec tunnels',
          label: 'IPSec tunnels',
          icon: 'ipsec-tunnel',
        });
      }

      // Settings
      builder
        .withLink(paths.pages.SettingsOrgPage, {
          id: 'nav-settings-organization',
          group: 'Settings',
          display: 'Organization',
          label: 'Organization settings',
          icon: 'company',
        })
        .withLink(paths.pages.SettingsUserPage, {
          id: 'nav-settings-user',
          group: 'Settings',
          display: 'User',
          label: 'User settings',
          icon: 'user',
        })

        .withNode(
          state.nodeFactory.directory({
            id: 'switch-companies',
            children: sortedCompanySlugsIgnoreCurrent.map((slug) =>
              state.nodeFactory.action({
                id: slug,
                display: `Switch to ${slug}`,
                label: `Switch to ${slug}`,
                icon: 'arrow-right',
                onSelect() {
                  changeCurrentCompany(slug);
                },
              }),
            ),
            display: 'Switch company…',
            label: 'Switch company…',
            group: 'Organization',
            icon: 'company',
            priority: Priority.Low,
          }),
        )
        .withNode(
          state.nodeFactory.directory({
            id: 'switch-location',
            children: companyNetworks.map((companyNetwork) =>
              state.nodeFactory.action({
                id: companyNetwork.UUID,
                display: `Switch to ${companyNetwork.label} (${companyNetwork.slug})`,
                label: `Switch to ${companyNetwork.label} (${companyNetwork.slug})`,
                icon: 'arrow-right',
                onSelect() {
                  const link = getNextBestCurrentPathForNetwork({
                    companyName,
                    networkSlug: companyNetwork.slug,
                    crumbs,
                    currentPathname: window.location.pathname,
                  });
                  navigate(link);
                },
              }),
            ),
            display: 'Switch location…',
            label: 'Switch location…',
            group: 'Organization',
            icon: 'location',
            priority: Priority.Low,
          }),
        );
    }

    builder.withAction({
      id: 'signout',
      display: 'Sign out',
      label: 'Sign out',
      group: 'Account',
      icon: 'signout',
      priority: Priority.Low,
      onSelect() {
        logout();
      },
    });
    return builder.build();
  }, [
    companyName,
    network,
    defaultNetwork?.slug,
    navigate,
    state.nodeFactory,
    flags,
    isOperator,
    isNetworkCustomer,
    sortedCompanySlugsIgnoreCurrent,
    bugReportTemplate,
    debugData,
    companyNetworks,
    crumbs,
    changeCurrentCompany,
    initialSerialNumber,
    logout,
    getAccessLevel,
  ]);

  useRegisterCommands(nodes.filter(isDefined), [
    ...sortedCompanySlugsIgnoreCurrent,
    network?.UUID,
    network?.slug,
  ]);

  useNavigateToSerialNumberCommand();
  useNavigateToVirtualDeviceCommand();
  useNavigateToNetworkCommand();

  const dropdownWidth =
    breakpoint !== 'mobile'
      ? ((breakpoint === 'desktop'
          ? dockValues.dropdown['@desktop']
          : dockValues.dropdown['@tablet']) as SpacingScale)
      : undefined;

  return (
    <DropdownMenu>
      <Tool
        fallback={fallback}
        as={DropdownMenuTrigger}
        sigil={{
          identity: identity.username,
          label: namesAreDefined
            ? `${identity.first_name} ${identity.last_name}`
            : identity.username,
          type: 'user',
        }}
        label={namesAreDefined ? identity.first_name : 'Account'}
      />
      <DropdownMenuPopover
        align="end"
        collisionPadding={12}
        side="bottom"
        sideOffset={4}
        width={dropdownWidth}
      >
        <DropdownMenuGroup>
          <AccountDropdownInfo>
            <Sigil
              size={32}
              identity={identity.username}
              label={
                namesAreDefined ? `${identity.first_name} ${identity.last_name}` : identity.username
              }
              type="user"
            />
            <VStack>
              {namesAreDefined && (
                <Small weight="extra-bold" whitespace="no-wrap">
                  {identity.first_name} {identity.last_name}
                </Small>
              )}
              <Small whitespace="no-wrap">{identity.username}</Small>
            </VStack>
          </AccountDropdownInfo>
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <DropdownMenuItem
            icon="preferences"
            onClick={() =>
              navigate(makeLink(paths.pages.SettingsUserPreferencesPage, { companyName }))
            }
          >
            Account settings
          </DropdownMenuItem>
          <DropdownMenuItem icon="signout" onSelect={logout}>
            Sign out
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuPopover>
    </DropdownMenu>
  );
}

export const Account = observer(AccountInner);
