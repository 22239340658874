// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';

import type { MeterLDFlags } from '../feature_flags';
import { useShouldDangerouslySkipFeatureChecksForOperators } from '../components/SkipFeatureChecksProvider';
import { LOCAL_LD_FLAG_VALUES } from '../feature_flags';
import { getRealm, Realm } from '../utils/realm';

export function useFeatureFlags(): MeterLDFlags {
  const flags = useFlags() as MeterLDFlags;
  const skipFeatureChecks = useShouldDangerouslySkipFeatureChecksForOperators();

  if (skipFeatureChecks) {
    return Object.fromEntries(
      Object.entries({
        ...LOCAL_LD_FLAG_VALUES,
        ...flags,
      }).map(([key, value]) => [key, typeof value === 'boolean' ? true : value]),
    );
  }

  const realm = getRealm();

  if (realm === Realm.LOCAL) {
    return LOCAL_LD_FLAG_VALUES;
  }

  return flags;
}
