import { match } from 'ts-pattern';

import type { CSS } from '../../stitches.config';

export type WrapTypes = 'nowrap' | 'wrap' | 'wrap-reverse';
export type WrapProps = { wrap?: WrapTypes };

export const wrapCSS = (wrap?: WrapTypes): CSS =>
  match(wrap)
    .with('nowrap', () => ({ flexWrap: 'nowrap' }))
    .with('wrap', () => ({ flexWrap: 'wrap' }))
    .with('wrap-reverse', () => ({ flexWrap: 'wrap-reverse' }))
    .with(undefined, () => ({}))
    .exhaustive();
