import type { Location } from 'history';
import type { To } from 'react-router-dom';
import { createRedirectRouteFn } from '@meterup/common';
import { useMemo } from 'react';
import dialogRoutes from 'virtual:pagefiles/dialogs';
import drawerRoutes from 'virtual:pagefiles/drawers';
import pageRoutes from 'virtual:pagefiles/pages';

import { paths, RootConnectPage } from '../constants';
import { useActiveControllerForNetwork } from '../hooks/useActiveControllerForNetwork';
import { type Network, useNetworkOrNull } from '../hooks/useNetworkFromPath';
import { useNetworksForCompany } from '../hooks/useNetworksForCompany';
import { Nav } from '../nav';
import { useDefaultCompany } from '../providers/CurrentCompanyProvider';
import { useDefaultNetwork } from '../providers/DefaultNetworkProvider';
import { useCurrentRoleOrGuest } from '../providers/RoleProvider';
import AppLogin from '../routes/pages/AppLogin.page';
import GuestPage from '../routes/pages/Guest.page';
import NotFoundPage from '../routes/pages/NotFound.page';
import UserBoardCompanyRoot from '../routes/pages/userboard/UserBoardCompanyRoot';
import UserBoardRootRedirect from '../routes/pages/userboard/UserBoardRootRedirect';
import { makeLink } from '../utils/main_and_drawer_navigation';
import { DNSSecurityTab } from './DNSSecurity/utils';
import { AccessPointEditTab, AccessPointsTab } from './Hardware/AccessPoints/enums';
import { PDUEditTab, PDUsTab, PDUTab } from './Hardware/PowerDistributionUnits/enums';
import {
  SecurityApplianceEditTab,
  SecurityAppliancesTab,
} from './Hardware/SecurityAppliance/enums';
import { SwitchEditTab, SwitchesTab, SwitchTab } from './Hardware/Switches/enums';
import { VLANDetailsTab } from './NetworkWide/VLANs/utils';
import { ValidWIDSTab } from './Wireless/WIDS/utils';

interface RedirectContext {
  companyName: string;
  networksForCompany: Network[];
  network: Network | null;
}

const extractSearchIfNecessary = (to: To) => {
  if (!!to && typeof to === 'object' && to.pathname) {
    const index = to.pathname.indexOf('?');

    if (index > -1) {
      const { search = '?' } = to;
      return {
        ...to,
        pathname: to.pathname.slice(0, index),
        search: search + to.pathname.slice(index + 1),
      };
    }
  }

  return to;
};

export const defaultMakeTo = (
  regions: Record<string, Location | null | undefined>,
  path: string | null,
  params: Record<string, string>,
) =>
  extractSearchIfNecessary(
    Nav.makeTo({ ...regions, root: extractSearchIfNecessary(path ? makeLink(path, params) : '/') }),
  );

export const defaultDrawerMakeTo = (
  regions: Record<string, Location | null | undefined>,
  path: string | null,
  params: Record<string, string>,
) =>
  extractSearchIfNecessary(
    Nav.makeTo({
      ...regions,
      drawer: path ? extractSearchIfNecessary(makeLink(path, params)) : null,
    }),
  );

const GuestRoutes = () =>
  Nav.useRoutes('root', [
    { path: '*', element: <NotFoundPage /> },
    { path: '/', element: <GuestPage /> },
    { path: 'org/:companyName', element: <GuestPage /> },
    { path: 'org/:companyName/guest', element: <GuestPage /> },
    { path: '/app-login', element: <AppLogin /> },
  ]);
const MemberRoutes = () =>
  Nav.useRoutes('root', [
    { path: '*', element: <NotFoundPage /> },
    { path: '/', element: <UserBoardRootRedirect /> },
    { path: '/org/:companyName', element: <UserBoardCompanyRoot /> },
    { path: '/app-login', element: <AppLogin /> },
  ]);

const redirectFromControllerToNetworkWithExtraParams =
  <R extends Record<string, string>>(extraParams: R) =>
  <T,>(params: T, context: RedirectContext) => {
    const networkSlug: string | undefined =
      (params &&
      typeof params === 'object' &&
      'networkSlug' in params &&
      typeof params.networkSlug === 'string'
        ? params.networkSlug
        : undefined) ??
      context?.network?.slug ??
      context?.networksForCompany?.find((network) => {
        if (params && typeof params === 'object' && 'controllerName' in params) {
          return network.virtualDevices.some(
            (vd) => vd.hardwareDevice?.serialNumber === params.controllerName,
          );
        }

        return false;
      })?.slug;

    if (!networkSlug) return false;

    return {
      ...context,
      ...params,
      networkSlug,
      ...extraParams,
    };
  };

const redirectFromControllerToNetwork = redirectFromControllerToNetworkWithExtraParams({});

const redirectWithExtraParams =
  <R extends Record<string, string>>(extraParams: R) =>
  <T,>(params: T, context: RedirectContext) => ({
    ...context,
    ...params,
    ...extraParams,
  });

const AdminRoutes = () => {
  const companyName = useDefaultCompany();
  const networksForCompany = useNetworksForCompany(companyName);
  const network = useNetworkOrNull();
  const { defaultNetwork } = useDefaultNetwork();

  const redirectContext: RedirectContext = useMemo(
    () => ({
      companyName,
      networksForCompany,
      network: network ?? defaultNetwork,
    }),
    [companyName, network, defaultNetwork, networksForCompany],
  );

  const regions = Nav.useRegionLocations();

  const createRedirect = useMemo(
    () => createRedirectRouteFn(defaultMakeTo, redirectContext, '/', regions, regions.root),
    [redirectContext, regions],
  );

  const activeNetworkController = useActiveControllerForNetwork(network);
  const initialSerialNumber =
    activeNetworkController?.hardwareDevice?.serialNumber ??
    network?.virtualDevices[0]?.hardwareDevice?.serialNumber;

  return Nav.useRoutes('root', [
    {
      children: [
        createRedirect(
          '/org/:companyName/controller/:controllerName',
          paths.pages.IndividualNetworkRootPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect('/support', paths.pages.SupportPage),
        createRedirect(RootConnectPage, paths.pages.ConnectDashboardAllLocationsPage),
        createRedirect('/events', paths.pages.EventLogPage, redirectFromControllerToNetwork),
        createRedirect('/guest', paths.pages.GuestPage),
        createRedirect('/internet-wifi', paths.pages.SSIDsPage, redirectFromControllerToNetwork),
        createRedirect(
          '/join/:ssid',
          paths.pages.NetworkTabletJoinInstructionsPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/settings/network/configure',
          paths.pages.SettingsOrgNetworksPage,
          redirectFromControllerToNetwork,
        ),

        createRedirect(
          '/switches',
          paths.pages.SwitchesPage,
          redirectFromControllerToNetworkWithExtraParams({ tab: SwitchesTab.Current }),
        ),

        createRedirect(
          '/hardware/switches',
          paths.pages.SwitchesPage,
          redirectFromControllerToNetworkWithExtraParams({ tab: SwitchesTab.Current }),
        ),
        createRedirect(
          '/hardware/access-points',
          paths.pages.AccessPointsListPage,
          redirectFromControllerToNetworkWithExtraParams({ tab: AccessPointsTab.Current }),
        ),
        createRedirect(
          '/hardware/security-appliances',
          paths.pages.SecurityAppliancesListPage,
          redirectFromControllerToNetworkWithExtraParams({
            tab: SecurityAppliancesTab.Current,
          }),
        ),
        createRedirect(
          '/hardware/power-distribution-units',
          paths.pages.PowerDistributionUnitListPage,
          redirectFromControllerToNetworkWithExtraParams({
            tab: PDUsTab.Current,
          }),
        ),
        createRedirect(
          '/hardware/observers',
          paths.pages.ObserversListPage,
          redirectFromControllerToNetwork,
        ),

        createRedirect(
          '/config',
          paths.pages.DeviceConfigOverridesPage,
          redirectFromControllerToNetworkWithExtraParams({ serialNumber: initialSerialNumber! }),
        ),
        createRedirect(
          '/documents',
          paths.pages.SettingsOrgDocumentsPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/events',
          paths.pages.EventLogPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches',
          paths.pages.SwitchesPage,
          redirectWithExtraParams({ tab: SwitchesTab.Current }),
        ),

        createRedirect(
          '/org/:companyName/network/:networkSlug/hardware/access-points',
          paths.pages.AccessPointsListPage,
          redirectWithExtraParams({ tab: AccessPointsTab.Current }),
        ),

        // Dock top-level redirects
        createRedirect('/org/:companyName/hub', paths.pages.HubCardsPage),

        // Onboarding
        createRedirect(
          '/org/:companyName/network/:networkSlug/onboarding',
          paths.pages.NetworkOnboardingPage,
        ),

        // Redirects for top-level item in sidebar
        createRedirect(paths.pages.InsightsPage, paths.pages.InsightsNetworkPage),
        createRedirect(
          paths.pages.FirewallPage,
          paths.pages.RulesPage,
          redirectWithExtraParams({
            tab: 'vlans',
          }),
        ),
        createRedirect(paths.pages.DesignPage, paths.pages.TopologyPage),
        createRedirect(paths.pages.SecureTunnelsPage, paths.pages.ClientVPNPage),

        // Controller based URLs to network based
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations',
          paths.pages.RackElevationsPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/:tab',
          paths.pages.SwitchesPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/:uuid/:tab',
          paths.pages.SwitchDetailPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName',
          paths.pages.InsightsNetworkPage,
          redirectFromControllerToNetwork,
        ),

        createRedirect(
          '/org/:companyName/network/:networkSlug/captive-portals',
          paths.pages.CaptivePortalPage,
          redirectWithExtraParams({
            tab: 'configure',
          }),
        ),

        createRedirect(
          '/org/:companyName/network/:networkSlug/config',
          paths.pages.DeviceConfigOverridesPage,
          redirectWithExtraParams({ serialNumber: initialSerialNumber! }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall-rules',
          paths.pages.RulesPage,
          redirectWithExtraParams({
            tab: 'vlans',
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/port-forwarding',
          paths.pages.RulesPage,
          redirectWithExtraParams({
            tab: 'port-forwarding',
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/insights/clients/:macAddress',
          paths.pages.ClientInsightsPage,
        ),
        // *** START: deprecated hardware locations ***
        // power-distribution-units
        createRedirect(
          '/org/:companyName/network/:networkSlug/power-units/:tab',
          paths.pages.PowerDistributionUnitListPage,
          redirectWithExtraParams({
            tab: PDUsTab.Current,
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/power-units/:uuid/:tab',
          paths.pages.PowerDistributionUnitDetailPage,
          redirectWithExtraParams({
            tab: PDUTab.Outlets,
          }),
        ),
        // switches
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/:tab',
          paths.pages.SwitchesPage,
          redirectWithExtraParams({
            tab: SwitchesTab.Current,
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/:uuid/:tab',
          paths.pages.SwitchDetailPage,
          redirectWithExtraParams({
            tab: SwitchTab.Insights,
          }),
        ),
        // access-points
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/:uuid/:tab',
          paths.pages.AccessPointPage,
          redirectWithExtraParams({
            tab: AccessPointsTab.Insights,
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/list',
          paths.pages.AccessPointsListPage,
          redirectWithExtraParams({
            tab: AccessPointsTab.Current,
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/:uuid/:tab',
          paths.drawers.AccessPointDrawerPage,
          redirectWithExtraParams({
            tab: 'access-point',
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/:id/edit',
          paths.drawers.AccessPointEditDrawerPage,
          redirectWithExtraParams({
            tab: AccessPointEditTab.Config,
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/batch-edit/:accessPointUUIDs',
          paths.drawers.AccessPointBatchEditPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/create',
          paths.drawers.AccessPointCreatePage,
        ),
        // security-appliances
        createRedirect(
          '/org/:companyName/network/:networkSlug/hardware/security-appliances',
          paths.pages.SecurityAppliancesListPage,
          redirectWithExtraParams({
            tab: SecurityAppliancesTab.Current,
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/hardware/security-appliances/:uuid/host-monitoring',
          paths.pages.HostMonitoringPage,
        ),
        // *** END: deprecated hardware locations ***

        // Settings
        // // Organization
        // // // Network / Notifications -> Org / Notifications
        createRedirect(
          '/org/:companyName/settings/network/:networkSlug/notifications',
          paths.pages.SettingsOrgNotificationsPage,
        ),
        // // // Network / Notifications / Alerts -> Org / Notifications / Alerts
        createRedirect(
          '/org/:companyName/settings/network/:networkSlug/notifications/alerts',
          paths.pages.SettingsOrgNotificationsAlertsPage,
        ),
        // // // Network / Notifications / Reports -> Org / Notifications / Reports
        createRedirect(
          '/org/:companyName/settings/network/:networkSlug/notifications/reports',
          paths.pages.SettingsOrgNotificationsReportsPage,
        ),
        // // // Network / General -> Org / Networks
        createRedirect(
          '/org/:companyName/settings/network/:networkSlug/general',
          paths.pages.SettingsOrgNetworksPage,
        ),

        // Insights
        // // Clients
        createRedirect(
          '/org/:companyName/network/:networkSlug/insights/clients',
          paths.pages.ClientsDevicesPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/insights/clients/list',
          paths.pages.ClientsDevicesPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/insights/clients/cves',
          paths.pages.ClientsVulnerabilitiesCVEsPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/insights/clients/vulnerabilities',
          paths.pages.ClientsVulnerabilitiesCVEsPage,
        ),
        // // Topology (moved from Design to Insights)
        createRedirect(
          '/org/:companyName/network/:networkSlug/design/topology',
          paths.pages.TopologyPage,
        ),

        // Hardware
        // // Access points
        // // // Access point edit -> Access point edit config
        createRedirect(
          '/org/:companyName/network/:networkSlug/hardware/access-points/:id/edit',
          paths.drawers.AccessPointEditDrawerPage,
          redirectWithExtraParams({
            tab: AccessPointEditTab.Config,
          }),
        ),
        // // PDUs
        // // // PDU edit -> PDU edit config
        createRedirect(
          '/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid/edit',
          paths.drawers.PowerDistributionUnitEditPage,
          redirectWithExtraParams({
            tab: PDUEditTab.Config,
          }),
        ),
        // // Security appliances
        createRedirect(
          '/org/:companyName/network/:networkSlug/security-appliances/:uuid/summary',
          paths.drawers.SecurityApplianceSummaryPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/security-appliances/:virtualDeviceUUID/edit/general',
          paths.drawers.SecurityApplianceEditDrawerPage,
          redirectWithExtraParams({
            tab: SecurityApplianceEditTab.Config,
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/security-appliances/:virtualDeviceUUID/ports/:phyInterfaceUUID',
          paths.drawers.SecurityAppliancePortDetailPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/security-appliances/:virtualDeviceUUID/ports/:phyInterfaceUUID/edit',
          paths.drawers.SecurityAppliancePortEditPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/security-appliances/add',
          paths.drawers.SecurityApplianceCreateDrawerPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/security-appliances/high-availability/edit',
          paths.drawers.HighAvailabilityEditDrawerPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/hardware/security-appliances/high-availability/edit',
          paths.drawers.HighAvailabilityEditDrawerPage,
        ),
        // // Switches
        // // // Switch edit -> Switch edit config
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/:id/edit/general',
          paths.drawers.SwitchEditPage,
          redirectWithExtraParams({ tab: SwitchEditTab.Config }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/:id/edit/profile',
          paths.drawers.SwitchProfileEditPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/:uuid',
          paths.drawers.SwitchSummaryPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/:virtualDeviceUUID/ports/batch-edit/:phyInterfaceUUIDs',
          paths.drawers.PortBatchEditPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/create',
          paths.drawers.SwitchCreatePage,
        ),

        // Network-wide
        // // ISPs
        // // // ISP insights -> ISPs list
        createRedirect(
          '/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/insights',
          paths.pages.ISPsPage,
        ),
        // // // ISP events -> ISPs list
        createRedirect(
          '/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/events',
          paths.pages.ISPsPage,
        ),
        // // VLANs
        // // // VLAN details -> VLAN insights
        createRedirect(
          '/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid/details',
          paths.pages.VLANDetailsPage,
          redirectWithExtraParams({
            tab: VLANDetailsTab.Insights,
          }),
        ),
        // // Rate limiting (moved from Firewall to Network-wide)
        // // // Rules list
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall/rate-limiting',
          paths.pages.RateLimitingPage,
        ),
        // // // Add rule
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall/rate-limiting/add',
          paths.drawers.RateLimitingRuleAddPage,
        ),

        // Wireless
        // // Security -> WIDS
        createRedirect(
          '/org/:companyName/network/:networkSlug/wireless/security',
          paths.pages.WIDSPage,
          redirectWithExtraParams({
            tab: ValidWIDSTab.RogueAPs,
          }),
        ),
        // // // Rouges APs
        createRedirect(
          '/org/:companyName/network/:networkSlug/wireless/security/rogue-access-points',
          paths.pages.WIDSPage,
          redirectWithExtraParams({
            tab: ValidWIDSTab.RogueAPs,
          }),
        ),
        // // // Honeypot SSIDs
        createRedirect(
          '/org/:companyName/network/:networkSlug/wireless/security/honeypot-ssids',
          paths.pages.WIDSPage,
          redirectWithExtraParams({
            tab: ValidWIDSTab.HoneypotSSIDs,
          }),
        ),
        // // // Allowlist
        createRedirect(
          '/org/:companyName/network/:networkSlug/wireless/security/allow-list',
          paths.pages.WIDSPage,
          redirectWithExtraParams({
            tab: ValidWIDSTab.Allowlist,
          }),
        ),

        // Firewall
        // // Legacy tab-based rules page
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall/rules/:tab',
          paths.pages.RulesPage,
        ),

        // // Rules -> ACLs
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall/rules',
          paths.pages.RulesPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall/rules/add',
          paths.drawers.CreateFirewallRulePage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall/rules/:ruleUUID',
          paths.drawers.FirewallRuleDrawerPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall/rules/:ruleUUID/edit',
          paths.drawers.FirewallRuleEditPage,
        ),

        // // DNS security
        // // // DNS security / VLANs -> DNS security
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall/dns-security/vlans',
          paths.pages.DNSSecurityPage,
        ),
        // // // DNS security create rule -> DNS security add rule
        createRedirect(
          '/org/:companyName/network/:networkSlug/dns-security/create',
          paths.drawers.DNSSecurityRuleAddPage,
        ),
        // // // DNS security VLAN create rule -> DNS security add rule
        createRedirect(
          '/org/:companyName/network/:networkSlug/dns-security/vlans/add',
          paths.drawers.DNSSecurityRuleAddPage,
        ),
        // // // DNS security rule edit -> DNS security rule edit
        createRedirect(
          '/org/:companyName/network/:networkSlug/dns-security/:ruleUUID',
          paths.drawers.DNSSecurityRuleEditPage,
          redirectWithExtraParams({
            tab: DNSSecurityTab.VLANs,
          }),
        ),
        // // Rate limiting
        // // // Rate limiting create rule -> Rate limiting add rule
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall/rate-limiting/create',
          paths.drawers.RateLimitingRuleAddPage,
        ),

        // Secure tunnels
        // // Create VPN server -> Add VPN endpoint
        createRedirect(
          '/org/:companyName/network/:networkSlug/secure-tunnels/vpn/create',
          paths.drawers.ClientVPNServerCreatePage,
        ),
        // // Create IPSec tunnel -> Add IPSec tunnel
        createRedirect(
          '/org/:companyName/network/:networkSlug/ipsec-tunnels/create',
          paths.drawers.AddIPSecTunnelDrawerPage,
        ),
        // // IPSec tunnel drawer -> IPSec tunnel drawer
        createRedirect(
          '/org/:companyName/network/:networkSlug/ipsec-tunnels/:IPSecUUID',
          paths.drawers.IPSecTunnelDrawerPage,
        ),
      ],
    },
    {
      children: pageRoutes,
    },
  ]);
};

export const MainRoutes = () => {
  const role = useCurrentRoleOrGuest();

  if (role === 'admin') return AdminRoutes();
  if (role === 'member') return MemberRoutes();

  return GuestRoutes();
};

export const DrawerRoutes = () => {
  const companyName = useDefaultCompany();
  const networksForCompany = useNetworksForCompany(companyName);
  const network = useNetworkOrNull();

  const redirectContext: RedirectContext = useMemo(
    () => ({
      companyName,
      networksForCompany,
      network,
    }),
    [companyName, networksForCompany, network],
  );

  const regions = Nav.useRegionLocations();
  const drawerLocation = Nav.useRegionLocation('drawer');

  const createRedirect = useMemo(
    () =>
      createRedirectRouteFn(defaultDrawerMakeTo, redirectContext, null, regions, drawerLocation),
    [redirectContext, regions, drawerLocation],
  );

  return Nav.useRoutes('drawer', [
    {
      children: [
        // Controller based URLs to network based
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations/:elevationUUID/attached-device/:deviceUUID',
          paths.drawers.EditRackMountPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations/:elevationUUID/attached-device/add',
          paths.drawers.AttachDeviceDrawerPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations/:elevationUUID/notes/:noteIndexStart',
          paths.drawers.EditRackNotePage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations/:elevationUUID/notes/add',
          paths.drawers.AddRackNotePage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations/attach-hardware',
          paths.drawers.AssignHardwareToNetworkPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/:virtualDeviceUUID/ports/:phyInterfaceUUID',
          paths.drawers.SwitchPortDetailPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/:virtualDeviceUUID/ports/:phyInterfaceUUID',
          paths.drawers.SwitchPortDetailPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/vlans/:uuid',
          paths.drawers.VLANEditPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/vlans/create',
          paths.drawers.VLANsAddPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/port-forwarding/:ruleUUID',
          paths.drawers.EditPortForwardingRulePage,
        ),
      ],
    },
    {
      children: drawerRoutes,
    },
  ]);
};

export const DialogRoutes = () =>
  Nav.useRoutes('dialog', [
    {
      children: dialogRoutes,
    },
  ]);
