import type { ZodEffects, ZodNullable, ZodOptional, ZodString } from 'zod';
import { Address4 } from 'ip-address';
import { BigInteger } from 'jsbn';
import * as z from 'zod';

import { isDefined } from '../helpers/isDefined';
import { truthy } from '../helpers/utils/truthy';

export { Address4 };

export { BigInteger };

export function safeParseAddress4(addr: string | null | undefined): Address4 | null {
  if (!addr) return null;

  try {
    return new Address4(addr);
  } catch (e) {
    return null;
  }
}

// From https://pkg.go.dev/net/netip#Addr.IsPrivate
export const PRIVATE_PREFIXES = ['10.0.0.0/8', '172.16.0.0/12', '192.168.0.0/16'] as const;

export function isPrivateAddress4(addr: Address4): boolean {
  return PRIVATE_PREFIXES.some((privatePrefix) => addr.isInSubnet(new Address4(privatePrefix)));
}

export function isPrivateAddress(addrString: string | null | undefined): boolean {
  const addr = safeParseAddress4(addrString);
  return addr != null && isPrivateAddress4(addr);
}

/**
 * If the IP range of the subnet contains only one address (i.e. is /32), returns the address.
 * Otherwise, returns the second address in the range.
 */
export function getFirstUsableAddress(addr: Address4) {
  return addr.subnet === '/32' ? addr.endAddress() : addr.startAddressExclusive();
}

/**
 * If the IP range of the subnet contains only one address, returns that
 * address. If it contains two addresses, returns the second address. Otherwise,
 * returns the second-to-last address in the range.
 */
export function getLastUsableAddress(addr: Address4) {
  return ['/31', '/32'].includes(addr.subnet) ? addr.endAddress() : addr.endAddressExclusive();
}

export function refineIPv4String(val: string, ctx: z.RefinementCtx) {
  const address = safeParseAddress4(val);
  if (!isDefined(address)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Must be a valid IP address like xx.xx.xx.xx',
      fatal: true,
    });
    return false;
  }
  return true;
}

type OptionalOrRequiredString =
  | ZodEffects<
      ZodOptional<ZodNullable<ZodString>>,
      string | null | undefined,
      string | null | undefined
    >
  | ZodEffects<ZodString, string, string>;
export function refineIPV4Range(v: OptionalOrRequiredString, requireTruthy = true) {
  return v
    .refine(
      (val) => {
        if (!requireTruthy && !truthy(val)) return true;
        const address = safeParseAddress4(val)!;
        return address.correctForm() === address.startAddress().correctForm();
      },
      (val) => {
        if (!requireTruthy && !truthy(val)) return {};
        const address = safeParseAddress4(val)!;
        return {
          message: `Must be the lowest IP represented by the range. Expected ${address
            .startAddress()
            .correctForm()}.`,
        };
      },
    )
    .refine((val) => {
      if (!requireTruthy && !truthy(val)) return true;
      return safeParseAddress4(val)!.subnet !== '/32';
    }, 'Must not be a single IP address or /32 subnet');
}

export function validIPv4String() {
  return z.string().superRefine(refineIPv4String);
}

export function validIPv4Range() {
  return refineIPV4Range(validIPv4String());
}

export interface Address4Range {
  start: Address4;
  end: Address4;
}

export function isIPWithinRange(ip: Address4, range: Address4Range): boolean {
  return ip.bigInteger() >= range.start.bigInteger() && ip.bigInteger() <= range.end.bigInteger();
}

export function isIPWithinSubnet(ip: Address4, cidr: string): boolean {
  const subnet = safeParseAddress4(cidr);
  if (!subnet) return false;

  return ip.isInSubnet(subnet);
}

export function isIPStringWithinSubnet(ipStr: string, cidr: string): boolean {
  const ip = safeParseAddress4(ipStr);
  const subnet = safeParseAddress4(cidr);
  if (!subnet || !ip) return false;

  return ip.isInSubnet(subnet);
}

/* eslint-disable no-bitwise */
export function isValidCIDR(ip: string, prefixLength: number): boolean {
  const ipInt = ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0);

  const maskInt = -1 << (32 - prefixLength);

  return (ipInt & maskInt) === ipInt;
}

export function splitCIDR(cidr: string): [string, number] | undefined {
  const pieces = cidr.split('/');
  if (pieces.length !== 2) return undefined;

  const [address, lengthStr] = pieces;
  const prefixLength = Number.parseInt(lengthStr, 10);
  if (Number.isNaN(prefixLength) || !Number.isInteger(prefixLength)) return undefined;

  return [address, prefixLength];
}

export function isValidCIDRString(cidr: string): boolean {
  const pieces = splitCIDR(cidr);
  if (!pieces) return false;

  return isValidCIDR(...pieces);
}

export function getNextAvailableSubnet(
  startAddr: Address4,
  existingAddrs: Address4[],
): Address4 | null {
  let addr: Address4 | null = startAddr;

  const step = new BigInteger((2 ** (32 - addr.subnetMask)).toString());

  for (let i = 0; i < existingAddrs.length && addr; ) {
    if (addr.isInSubnet(existingAddrs[i])) {
      const newAddressWithoutPrefix = Address4.fromBigInteger(addr.bigInteger().add(step));
      addr = safeParseAddress4(`${newAddressWithoutPrefix.address}/${addr.subnetMask}`);
    } else {
      i += 1;
    }
  }

  return addr;
}
