import type { QueryResultField } from '@meterup/graphql';

import type {
  NosFeatureQueryQuery,
  NosFeaturesQueryQuery,
  NosVersionByIdQueryQuery,
  NosVersionsQueryQuery,
} from '../../gql/graphql';
import { graphql } from '../../gql';

graphql(`
  fragment NetworkFieldsForNOSVersion on Network {
    __typename
    UUID
    label
    slug
    isCustomer
    companySlug
    company {
      uuid
      name
      supportTier
    }
    mailingAddress {
      squareFeet
    }
    virtualDevices {
      hardwareDevice {
        deviceType
        deviceModel
      }
    }
    pinnedNOSVersionID
    pendingNOSVersionID
    lastUpgradedAt
    isActive
    nosUpgradeChannel
  }
`);

graphql(`
  fragment NosVersionFields on NosVersion {
    version
    id
    mc01Build
    mw07Build
    ms10Build
    mp01Build
    observerBuild
    isDeprecated
    isDefault
    updatedAt
    major
    minor
    patch

    networks {
      ...NetworkFieldsForNOSVersion
    }
  }
`);

export const nosVersionQuery = graphql(`
  query NosVersionQuery($id: Int!) {
    nosVersions(id: $id) {
      ...NosVersionFields
    }
  }
`);

export type NosVersionQueryResult = NonNullable<NosVersionsQueryQuery['nosVersions']>[number];

export const nosVersionsQuery = graphql(`
  query NosVersionsQuery {
    nosVersions {
      ...NosVersionFields
    }
    networks(filter: { active: { eq: true } }) {
      ...NetworkFieldsForNOSVersion
    }
  }
`);

export type NosVersionsQueryResult = QueryResultField<NosVersionsQueryQuery, 'nosVersions'>;

export const nosVersionByIDQuery = graphql(`
  query NosVersionByIDQuery($id: Int!) {
    nosVersions(id: $id) {
      id
      version
      isDeprecated
      isGA
      isDefault
      isLocked
      releaseNotes
      major
      minor
      patch
      updatedAt
      # cos
      mc01Build
      # wos
      mw07Build
      mw08Build
      mw09Build
      # sos
      ms10Build
      ms11Build
      # pos
      mp01Build
      # observers
      observerBuild
    }
  }
`);

export type NosVersionByIDQueryResult = QueryResultField<NosVersionByIdQueryQuery, 'nosVersions'>;

export const UpdateNOSVersionMutation = graphql(`
  mutation UpdateNOSVersionMutation($id: Int!, $input: NosVersionInput!) {
    updateNosVersion(id: $id, input: $input) {
      id
    }
  }
`);

export const CreateNOSVersionMutation = graphql(`
  mutation CreateNOSVersionMutation($input: NosVersionInput!) {
    createNosVersion(input: $input) {
      id
    }
  }
`);

export type NosFeaturesQueryResult = QueryResultField<NosFeaturesQueryQuery, 'nosFeatures'>;

export const nosFeaturesQuery = graphql(`
  query NosFeaturesQuery {
    nosFeatures {
      key
      uuid
      name
      description
      minMajorVersion
      minMinorVersion
      minPatchVersion
      maxMajorVersion
      maxMinorVersion
      maxPatchVersion
      featureFlag

      nosVersions {
        id
      }
    }
    networks(filter: { active: { eq: true } }) {
      ...NetworkFieldsForNOSVersion
    }
  }
`);

export type NosFeatureQueryResult = NosFeatureQueryQuery['nosFeature'];

export const nosFeatureQuery = graphql(`
  query NosFeatureQuery($uuid: UUID!) {
    nosFeature(UUID: $uuid) {
      uuid
      key
      name
      description
      minMajorVersion
      minMinorVersion
      minPatchVersion
      maxMajorVersion
      maxMinorVersion
      maxPatchVersion
      featureFlag
    }
  }
`);

export const updateNosFeatureMutation = graphql(`
  mutation UpdateNosFeatureMutation($uuid: UUID!, $input: UpdateNosFeatureInput!) {
    updateNosFeature(UUID: $uuid, input: $input) {
      uuid
    }
  }
`);

export const createNosFeatureMutation = graphql(`
  mutation CreateNosFeatureMutation($input: CreateNosFeatureInput!) {
    createNosFeature(input: $input) {
      uuid
    }
  }
`);
