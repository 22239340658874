export enum ObserversTab {
  List = 'list',
  Targets = 'targets',
  Tests = 'tests',
}

export enum ObserverTab {
  Tests = 'tests',
  Targets = 'targets',
}

export enum ObserverEditTab {
  Config = 'config',
  Hardware = 'hardware',
}
