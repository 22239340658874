import { checkDefinedOrThrow } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { FatalErrorFallback } from '../../components/ErrorFallback/ErrorFallback';
import { paths } from '../../constants';
import { UnauthenticatedError } from '../../errors';
import { graphql } from '../../gql';
import { VirtualDeviceType } from '../../gql/graphql';
import { Nav } from '../../nav';
import { makeLink } from '../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '/virtual-device/:virtualDeviceUUID',
});

function VirtualDeviceNotFoundError() {
  const error = new UnauthenticatedError('Device not found');

  return (
    <FatalErrorFallback error={error} componentStack={null} eventId="" resetError={() => null} />
  );
}

export const virtualDeviceForNavigationQuery = graphql(`
  query VirtualDeviceForNavigation($uuid: UUID!) {
    virtualDevice(UUID: $uuid) {
      UUID
      deviceType
      network {
        UUID
        slug
        companySlug
      }
    }
  }
`);

export default function VirtualDeviceRootPage() {
  const { virtualDeviceUUID } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.VirtualDeviceRootPage),
    new UnauthenticatedError('Device not found'),
  );
  const virtualDevice = useGraphQL(
    virtualDeviceForNavigationQuery,
    { uuid: virtualDeviceUUID },
    {
      useErrorBoundary: false,
    },
  ).data?.virtualDevice;
  const [params] = useSearchParams();

  const pathname = useMemo(() => {
    if (!virtualDevice?.network?.companySlug) return null;

    const commonParams = {
      uuid: virtualDevice.UUID,
      companyName: virtualDevice.network.companySlug,
      networkSlug: virtualDevice.network.slug,
      tab: 'insights',
    };

    switch (virtualDevice.deviceType) {
      case VirtualDeviceType.Observer:
        // TODO: Device page for observers?
        return null;
      case VirtualDeviceType.Controller:
        return makeLink(paths.pages.SecurityApplianceDetailPage, commonParams);
      case VirtualDeviceType.Switch:
        return makeLink(paths.pages.SwitchDetailPage, commonParams);
      case VirtualDeviceType.AccessPoint:
        return makeLink(paths.pages.AccessPointPage, commonParams);
      case VirtualDeviceType.PowerDistributionUnit:
        return makeLink(paths.pages.PowerDistributionUnitDetailPage, commonParams);
    }

    return null;
  }, [virtualDevice]);

  if (!virtualDevice?.network?.companySlug || !pathname) {
    return <VirtualDeviceNotFoundError />;
  }

  return (
    <Navigate
      to={{
        pathname,
        search: params.toString(),
      }}
      replace
    />
  );
}
