import type { SortingState } from '@meterup/common';
import {
  Badge,
  ComboBox,
  ComboBoxItem,
  FieldContainer,
  PrimaryField,
  Segment,
  Segments,
} from '@meterup/atto';
import { AutoTable, isDefinedAndNotEmpty } from '@meterup/common';
import { useFormikContext } from 'formik';
import { z } from 'zod';

import type {
  HoneypotSsiDsQuery,
  NetworkThreatAllowlistForNetworkQuery,
  RogueAccessPointsQuery,
} from '../../../gql/graphql';
import type { SSIDsQueryResult } from '../SSIDs/SSIDsUtils';
import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { CreateNetworkThreatAllowlistEntryInputSchema } from '../../../gql/zod-types';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { fromISOtoLocaleString } from '../../../utils/time';
import { FieldProvider } from '../../Form/FieldProvider';
import { TextField } from '../../Form/Fields';
import { FormikConditional } from '../../FormikConditional';
import { createColumnBuilder } from '../../Table/createColumnBuilder';

export enum ValidWIDSTab {
  RogueAPs = 'rogue-aps',
  HoneypotSSIDs = 'honeypot-ssids',
  Allowlist = 'allowlist',
}

export type RogueAccessPoint = NonNullable<
  RogueAccessPointsQuery['rogueAccessPointsObservedOnNetwork']
>[number];

export const RogueAPsQuery = graphql(`
  query RogueAccessPoints($networkUUID: UUID!) {
    rogueAccessPointsObservedOnNetwork(
      networkUUID: $networkUUID
      filter: { timeFilter: { durationSeconds: 600, stepSeconds: 30 } }
    ) {
      networkUUID
      macAddress
      phyInterface {
        UUID
        portNumber
        virtualDevice {
          UUID
          label
        }
      }
      observers {
        observedAt
        rssi
        noiseFloor
        virtualDevice {
          UUID
          label
        }
      }
    }
  }
`);

export type Honeypot = NonNullable<HoneypotSsiDsQuery['honeypotSSIDsObservedOnNetwork']>[number];

export const HoneypotSSIDsQuery = graphql(`
  query HoneypotSSIDs($networkUUID: UUID!) {
    honeypotSSIDsObservedOnNetwork(
      networkUUID: $networkUUID
      filter: { timeFilter: { durationSeconds: 600, stepSeconds: 30 } }
    ) {
      networkUUID
      ssid
      broadcasters {
        bssid
        observers {
          observedAt
          rssi
          noiseFloor
          virtualDevice {
            __typename
            UUID
            label
          }
        }
      }
    }
  }
`);

export type NetworkThreatAllowlistEntry = NonNullable<
  NetworkThreatAllowlistForNetworkQuery['networkThreatAllowlistForNetwork']
>[number];

export const NetworkThreatAllowlistQuery = graphql(`
  query NetworkThreatAllowlistForNetwork($networkUUID: UUID!) {
    networkThreatAllowlistForNetwork(networkUUID: $networkUUID) {
      UUID
      reason
      wiredMAC
      ssidUUID
      updatedAt
    }
  }
`);

export const createNetworkThreatAllowlistentryInputSchema =
  CreateNetworkThreatAllowlistEntryInputSchema.extend({
    entryType: z.enum(['wiredMAC', 'ssidUUID']),
  })
    .refine(
      ({ entryType, wiredMAC }) => entryType !== 'wiredMAC' || isDefinedAndNotEmpty(wiredMAC),
      {
        message: 'MAC Address is required',
        path: ['wiredMAC'],
      },
    )
    .refine(
      ({ entryType, ssidUUID }) => entryType !== 'ssidUUID' || isDefinedAndNotEmpty(ssidUUID),
      {
        message: 'SSID is required',
        path: ['ssidUUID'],
      },
    );

export type NetworkThreatAllowlistEntryFormValues = z.infer<
  typeof createNetworkThreatAllowlistentryInputSchema
>;

export const createNetworkThreatAllowlistEntryMutation = graphql(`
  mutation CreateNetworkThreatAllowlistEntry(
    $networkUUID: UUID!
    $input: CreateNetworkThreatAllowlistEntryInput!
  ) {
    createNetworkThreatAllowlistEntry(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateNetworkThreatAllowlistEntryMutation = graphql(`
  mutation UpdateNetworkThreatAllowlistEntry(
    $uuid: UUID!
    $input: UpdateNetworkThreatAllowlistEntryInput!
  ) {
    updateNetworkThreatAllowlistEntry(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteNetworkThreatAllowlistEntryMutation = graphql(`
  mutation DeleteNetworkThreatAllowlistEntry($uuid: UUID!) {
    deleteNetworkThreatAllowlistEntry(UUID: $uuid) {
      UUID
    }
  }
`);

export const widsObserverTableBuilder = createColumnBuilder<
  RogueAccessPoint['observers'][number] | Honeypot['broadcasters'][number]['observers'][number]
>();

export const widsObserverTableColumns = [
  widsObserverTableBuilder.data((row) => row.virtualDevice.label, {
    id: 'seen-by',
    header: 'Observed by',
    meta: {
      isLeading: true,
    },
  }),
  widsObserverTableBuilder.data((row) => row.rssi - row.noiseFloor, {
    id: 'snr',
    header: 'SNR',
    cell: ({ value }) => (
      <Badge arrangement="leading-icon" ends="card" icon="noise" size="small" type="neutral">
        {value}
      </Badge>
    ),
  }),
  widsObserverTableBuilder.data((row) => fromISOtoLocaleString({ iso: row.observedAt }), {
    id: 'observed-at',
    header: 'Observed at',
    meta: {
      alignment: 'end',
    },
  }),
];

function GetLinkTo(
  row:
    | RogueAccessPoint['observers'][number]
    | Honeypot['broadcasters'][number]['observers'][number],
) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  if (row.virtualDevice.__typename === 'AccessPointVirtualDevice') {
    return makeDrawerLink(window.location, paths.drawers.AccessPointDrawerPage, {
      companyName,
      networkSlug: network.slug,
      uuid: row.virtualDevice.UUID,
      tab: 'access-point',
    });
  }

  if (row.virtualDevice.__typename === 'ObserverVirtualDevice') {
    return makeDrawerLink(window.location, paths.drawers.ObserverDrawerPage, {
      companyName,
      networkSlug: network.slug,
      uuid: row.virtualDevice.UUID,
    });
  }

  return null;
}

export function WIDSObserverTable({
  data,
}: {
  data: RogueAccessPoint | Honeypot['broadcasters'][number];
}) {
  const apParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointDrawerPage);
  const observerParams = Nav.useRegionParams('drawer', paths.drawers.ObserverDrawerPage);

  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('observersSort', [
    {
      id: 'snr',
      desc: false,
    },
  ]);

  return (
    <AutoTable
      data={data.observers}
      columns={widsObserverTableColumns}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      isRowSelected={(row) =>
        apParams?.uuid === row.virtualDevice.UUID || observerParams?.uuid === row.virtualDevice.UUID
      }
      getLinkTo={(row) => GetLinkTo(row)}
    />
  );
}

function WiredMACField() {
  return (
    <TextField
      name="wiredMAC"
      label="MAC Address"
      description="Any device plugged into a Meter switch with this MAC address will not be considered a rogue access point."
    />
  );
}

export function NetworkThreatAllowlistEntryTypeField({ ssids }: { ssids: SSIDsQueryResult[] }) {
  const { values, setFieldValue } = useFormikContext<NetworkThreatAllowlistEntryFormValues>();

  if (ssids.length === 0) {
    return <WiredMACField />;
  }

  return (
    <>
      <FieldContainer>
        <FieldProvider name="entryType">
          <Segments>
            <Segment
              active={values.entryType === 'wiredMAC'}
              onClick={() => setFieldValue('entryType', 'wiredMAC')}
            >
              MAC Address
            </Segment>
            <Segment
              active={values.entryType === 'ssidUUID'}
              onClick={() => setFieldValue('entryType', 'ssidUUID')}
            >
              SSID
            </Segment>
          </Segments>
        </FieldProvider>
      </FieldContainer>
      <FormikConditional<NetworkThreatAllowlistEntryFormValues>
        condition={(v) => v.entryType === 'wiredMAC'}
      >
        <WiredMACField />
      </FormikConditional>
      <FormikConditional<NetworkThreatAllowlistEntryFormValues>
        condition={(v) => v.entryType === 'ssidUUID'}
      >
        <FieldProvider name="ssidUUID">
          <PrimaryField
            label="SSID"
            description="Any device broadcasting an SSID matching this SSID will not be considered a honeypot SSID."
            element={
              <ComboBox placeholder="Select SSID" canClearValue>
                {ssids.map((ssid) => (
                  <ComboBoxItem key={ssid.UUID} textValue={ssid.ssid}>
                    {ssid.ssid}
                  </ComboBoxItem>
                ))}
              </ComboBox>
            }
          />
        </FieldProvider>
      </FormikConditional>
    </>
  );
}
