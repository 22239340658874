import {
  Body,
  Box,
  colors,
  darkThemeSelector,
  Icon,
  Shortcut,
  Small,
  styled,
  useViewport,
} from '@meterup/atto';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { ScoredNode } from '../core/filter';
import { OPERATOR_ACTIONS_GROUP_NAME, ROOT_ID } from '../core';
import { useCommandItemEvents } from './hooks/useCommandItemEvents';
import { useCommand } from './Root';

const ResultIcon = styled(Icon, {
  color: '$$iconColor',
});

const ResultLabel = styled(Body, {
  color: '$$labelColor',
  fontWeight: '$bold',
});

const ResultAncestor = styled(Small, {
  display: 'flex',
  flexShrink: 0,
  fontWeight: '$bold',
  color: '$$ancestorColor',
});

const ResultRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$8',
  padding: '$8 $12',
  borderRadius: '$8',
  cursor: 'pointer',
  truncate: true,

  variants: {
    active: {
      true: {
        background: colors.bgBrandLight,

        $$iconColor: colors.bodyBrandLight,
        $$labelColor: colors.headingBrandLight,
        $$ancestorColor: colors.bodyBrandLight,

        [darkThemeSelector]: {
          background: colors.bgBrandDark,

          $$iconColor: colors.bodyBrandDark,
          $$labelColor: colors.headingBrandDark,
          $$ancestorColor: colors.bodyBrandDark,
        },
      },
      false: {
        color: colors.bodyNeutralLight,

        [darkThemeSelector]: {
          color: colors.bodyNeutralDark,
        },
      },
    },
    internal: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      active: true,
      internal: true,
      css: {
        background: colors.internalBgLight,

        $$iconColor: colors.internalBodyLight,
        $$labelColor: colors.internalHeadingLight,
        $$ancestorColor: colors.internalBodyLight,

        [darkThemeSelector]: {
          background: colors.internalBgDark,

          $$iconColor: colors.internalBodyDark,
          $$labelColor: colors.internalHeadingDark,
          $$ancestorColor: colors.internalBodyDark,
        },
      },
    },
  ],
});

const GroupLabel = styled(Small, {
  display: 'flex',
  padding: '$4 $12',
  color: colors.controlContentPlaceholderLight,

  [darkThemeSelector]: {
    color: colors.controlContentPlaceholderDark,
  },

  '&:not(:first-child)': {
    paddingTop: '$20',
  },
});

const isMac = window.navigator.platform === 'MacIntel';

const getFormattedKey = (key: string) => {
  switch (key) {
    case '$mod': {
      if (isMac) {
        return '⌘';
      }
      return 'Ctrl';
    }
    default: {
      return key;
    }
  }
};

const RenderShortcut = observer((props: { shortcut: string }) => {
  const shortcuts = React.useMemo(() => props.shortcut.split(/[+, ]+/), [props.shortcut]);

  return shortcuts.map((s) => <Shortcut keys={[getFormattedKey(s)]} />);
});

const RenderNode = observer((props: { scoredNode: ScoredNode; index: number }) => {
  const rSelf = React.useRef<HTMLDivElement>(null);

  const { state } = useCommand();

  const getEventProps = useCommandItemEvents();

  const { node } = props.scoredNode;

  const active = state.ui.activeNode?.node === node;

  const block =
    props.index === 0 ||
    // Only apply `end` if the previous item is a group name
    (props.index === 1 && typeof state.filter.ordered[0] === 'string')
      ? 'end'
      : 'nearest';

  React.useEffect(() => {
    const el = rSelf.current;
    if (active && el) {
      el.scrollIntoView({
        behavior: 'auto',
        block,
      });
    }
  }, [active, props.index, block]);

  if (node === state.currentRoot) {
    return null;
  }

  // TODO: If we allow custom rendering via `display`, we probably don't need to render ancestors.
  const renderAncestors = () => {
    const index = node.ancestors.findIndex((n) => n === state.currentRoot);
    const ancestors = node.ancestors.slice(index + 1).filter((a) => a.id !== ROOT_ID);

    const str = ancestors.map((a) => a.display).join(' → ');

    if (!str) {
      return null;
    }

    return <ResultAncestor data-ancestor>{str}</ResultAncestor>;
  };

  return (
    <ResultRow
      ref={rSelf}
      active={active}
      internal={node.internal}
      {...getEventProps(node, props.index)}
    >
      {node.icon ? <ResultIcon icon={node.icon} internal={node.internal} size={14} /> : null}
      {renderAncestors()}
      {typeof node.display === 'string' ? (
        <ResultLabel internal={node.internal}>{node.display}</ResultLabel>
      ) : (
        node.display
      )}
      {node.shortcut ? (
        <Box style={{ marginLeft: 'auto' }} data-shortcut>
          <RenderShortcut shortcut={node.shortcut} />
        </Box>
      ) : null}
    </ResultRow>
  );
});

const Result = observer((props: { result: string | ScoredNode; index: number }) => {
  if (typeof props.result === 'string') {
    // HACK: Hardcoded group for operator actions
    const internal = props.result === OPERATOR_ACTIONS_GROUP_NAME;

    return (
      <GroupLabel weight="extra-bold" internal={internal}>
        {props.result}
      </GroupLabel>
    );
  }

  return <RenderNode scoredNode={props.result} index={props.index} />;
});

function useAnimator() {
  const rPrevHeight = React.useRef<number | null>(null);
  const outer = React.useRef<HTMLDivElement | null>(null);
  const inner = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const outerEl = outer.current;
    const innerEl = inner.current;

    if (outerEl && innerEl) {
      const obs = new ResizeObserver((entries) => {
        const entry = entries[0];

        if (entry.contentBoxSize) {
          const contentBoxSize = entry.contentBoxSize[0];

          if (rPrevHeight.current !== null) {
            outerEl.animate(
              [
                {
                  height: `${rPrevHeight.current}px`,
                },
                {
                  height: `${contentBoxSize.blockSize}px`,
                },
              ],
              {
                duration: 75,
              },
            );
          }

          rPrevHeight.current = contentBoxSize.blockSize;
        }
      });

      obs.observe(innerEl);
      return () => {
        obs.unobserve(innerEl);
      };
    }

    return undefined;
  }, []);

  return { outer, inner };
}

const ResultsScroll = styled('div', {
  overflow: 'auto',
  padding: '0 $12 $12',
  scrollPaddingBottom: 12,

  '@notDesktop': {
    height: '100%',
  },

  '@desktop': {
    maxHeight: '300px',
  },
});

const ResultsInner = styled('div', {
  '@notDesktop': {
    height: '100%',
  },
});

const ResultsOuter = styled('div', {
  overflow: 'hidden',

  '@notDesktop': {
    height: '100%',
  },
});

export const Results = observer(() => {
  const { breakpoint } = useViewport();
  const { state } = useCommand();

  const results = state.filter.ordered;

  const { outer, inner } = useAnimator();

  const resultsRender =
    results.length > 0 ? (
      <ResultsScroll>
        {results.map((result, index) => (
          <Result
            key={typeof result === 'string' ? result : result.node.id}
            result={result}
            // TODO: We'll need to virtualize this list.
            index={index}
          />
        ))}
      </ResultsScroll>
    ) : null;

  return breakpoint === 'desktop' ? (
    <ResultsOuter ref={outer}>
      <ResultsInner ref={inner}>{resultsRender}</ResultsInner>
    </ResultsOuter>
  ) : (
    resultsRender
  );
});
