import type { UserTokenResponse } from '@meterup/proto/esm/api';
import { getMany, getOne, isDefined, makeAPICall, mutateVoid } from '@meterup/common';
import { api } from '@meterup/proto';
import { orderBy } from 'lodash-es';

import type { MeterV2WirelessTagServiceSet } from './config';
import type {
  ControllerStateResponse,
  FirmwareUpdates,
  LegacyControllerMessageData,
  LegacyNetworkInfoData,
  ProvidersData,
  SSIDData,
  SSIDPassword,
  SuggestedPasswordData,
  UserData,
  UsersTokensResponseJSON,
} from './types';
import { delay } from '../utils/delay';
import { getRealm, Realm } from '../utils/realm';
import { retry } from '../utils/retry';
import { axiosInstanceJSON } from './axiosInstanceJSON';
import FAKE_NETWORK_INFO_RESPONSE from './data/network_info';

export const getIdentity = async () =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<api.IdentityResponse>('/v1/identity');
    return result.data;
  });

export const startNetworkInfoRequest = async (
  networkName: string,
): Promise<LegacyControllerMessageData | null> =>
  getOne(async () => {
    const realm = getRealm();

    if (realm === Realm.LOCAL) {
      return delay(2500).then(() => ({ links: { self: '/' } }) as any);
    }

    const response = await axiosInstanceJSON.get<LegacyControllerMessageData>(
      `/v1/controllers/${networkName}/network-info?timeout=20s`,
    );

    return response.data;
  });

const fetchFromLegacyControllerLink = async <T extends any>(link: string): Promise<T> =>
  makeAPICall(async () => {
    const realm = getRealm();
    const response =
      realm === Realm.LOCAL
        ? await delay(80).then(() => FAKE_NETWORK_INFO_RESPONSE)
        : await axiosInstanceJSON.get<LegacyControllerMessageData>(link, { timeout: 800 });
    return JSON.parse(response.data?.response?.data!);
  });

export const fetchNetworkInfo = async (controllerName: string): Promise<LegacyNetworkInfoData> =>
  makeAPICall(async () => {
    const linkResponse = await startNetworkInfoRequest(controllerName);
    const link = linkResponse?.links?.self;
    if (link) {
      return retry(() => fetchFromLegacyControllerLink<LegacyNetworkInfoData>(link), 7);
    }
    throw new Error('Failed to read network info');
  });

export const fetchControllers = async (company: string): Promise<api.ControllerResponse[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.CompanyControllersResponse>(
      `/v1/companies/${company}/controllers`,
    );

    return result.data.controllers;
  });

export const fetchInstalledPrimaryControllers = async (company: string) => {
  const controllers = await fetchControllers(company);
  return controllers.filter(
    (controller) =>
      controller.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY,
  );
};

export const fetchControllerConfig = async (controller: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<{
      config: object;
      last_updated_at?: string;
    }>(`/v1/controllers/${controller}/config`);
    return result.data;
  });

export const upsertControllerConfigKey = async (
  controllerName: string,
  key: string,
  value: object,
) =>
  mutateVoid(async () =>
    axiosInstanceJSON.post(`/v1/controllers/${controllerName}/config/${key}`, {
      config: value,
    }),
  );

export const createUserFeedback = async (feedback: string, companyUserSid: string, url: string) =>
  mutateVoid(async () =>
    axiosInstanceJSON.post('/v1/user-feedback', {
      feedback,
      company_user_sid: companyUserSid,
      url,
    }),
  );

export const fetchPendingFirmwareUpdate = async (serial: string): Promise<FirmwareUpdates> =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get(`/v1/devices/${serial}/firmware-updates`);
    return result.data;
  });

export const fetchProviders = async (): Promise<api.Provider[]> =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<ProvidersData>('/v1/providers');
    return response.data.providers;
  });

export const getSuggestedPassword = async (wordCount: number) =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<SuggestedPasswordData>('/v1/password-suggestions', {
      params: {
        'word-count': wordCount,
      },
    });
    return result.data.suggested_password;
  });

export const getUserTokens = async (companyUserSid: string): Promise<UserTokenResponse[]> =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<UsersTokensResponseJSON>(
      `/v1/company-users/${companyUserSid}/tokens`,
    );

    return response.data.tokens;
  });

export const getUser = async (companySlug: string, userSid: string): Promise<UserData | null> =>
  getOne(async () => {
    const response = await axiosInstanceJSON.get<UserData>(
      `/v1/companies/${companySlug}/users/${userSid}`,
    );

    return response.data;
  });

export const logoutUser = async (): Promise<void> =>
  mutateVoid(async () => {
    await axiosInstanceJSON.post(`/v1/logout`);
  });

const cosServiceSetToSSIDData = (
  response: LegacyNetworkInfoData,
  ssid: string,
  json: MeterV2WirelessTagServiceSet,
): SSIDData => {
  const pskFromStateJSON = response.psk_rotation_state_json?.service_sets[ssid]?.PSK;
  const pskFromLegacyData = ssid === response.guest_ssid ? response.guest_password ?? '' : '';

  let password: SSIDPassword = { type: 'none', value: '' };

  if (json['psk-rotation']) {
    password = {
      type: 'rotating',
      value: pskFromStateJSON ?? pskFromLegacyData,
      rotation_interval_name: json['psk-rotation']?.frequency,
    };
  } else if (json.authentication) {
    password = { type: 'static', value: json.psk ?? '' };
  }

  return {
    ssid,
    type: json.bands?.join(', ') ?? 'Unknown',
    sid: ssid,
    password,
    authentication: json.authentication || null,
  };
};

// This function reshapes the network info data into a list of SSIDs. No
// endpoint exists that would return this data yet.
export async function fetchControllerSSIDs(controller: string): Promise<SSIDData[]> {
  const networkInfoData = await fetchNetworkInfo(controller);

  if (isDefined(networkInfoData)) {
    if (networkInfoData.config_json) {
      return Object.entries(networkInfoData.config_json['service-sets'] ?? {}).map(([ssid, json]) =>
        cosServiceSetToSSIDData(networkInfoData, ssid, json),
      );
    }

    return [
      {
        sid: 'private',
        ssid: networkInfoData.private_ssid,
        password: { type: 'static', value: networkInfoData.private_password } as const,
        type: '5 GHz',
        authentication: 'PSK2',
      },
      networkInfoData.private_2g_ssid
        ? {
            sid: 'private_2g',
            ssid: networkInfoData.private_2g_ssid,
            password: { type: 'static', value: networkInfoData.private_password } as const,
            type: '2.4 GHz',
            authentication: 'PSK2',
          }
        : null,
      networkInfoData.guest_ssid
        ? {
            sid: 'guest',
            ssid: networkInfoData.guest_ssid,
            password: networkInfoData.guest_password
              ? ({
                  type: 'rotating',
                  value: networkInfoData.guest_password,
                  rotation_interval_name: networkInfoData.guest_strategy,
                } as const)
              : null,
            type: 'Guest',
            authentication: null,
          }
        : null,
    ].filter(isDefined);
  }

  return [];
}

export async function fetchControllerSSID(
  controller: string,
  sid: string,
): Promise<SSIDData | null> {
  const networkInfoData = await fetchControllerSSIDs(controller);
  return networkInfoData.find((ssid) => ssid.sid === sid) ?? null;
}

export const listEnabledContentFiltersForCompany = async (companySlug: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.CompanyContentFiltersResponse>(
      `/v1/companies/${companySlug}/content-filters`,
    );
    return result.data;
  });

export const enableContentFilterForController = async (controllerName: string) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.post<api.ContentFilterLocation>(
      `/v1/controllers/${controllerName}/content-filters`,
    );
  });

export const getRulesForCompany = async (companySlug: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.ContentFilterRules>(
      `/v1/companies/${companySlug}/content-filters/rules`,
    );

    return orderBy(result.data.rules, (rule) => rule.precedence, 'asc');
  });

export const getRuleForCompany = async (companySlug: string, id: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.ContentFilterRule>(
      `/v1/companies/${companySlug}/content-filters/rules/${id}`,
    );
    return result.data;
  });

export const getRuleCategories = async (companySlug: string, domain?: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.ContentFilterRuleCategories>(
      `/v1/companies/${companySlug}/content-filters/rule-categories`,
      { params: { domain } },
    );
    return result.data.categories;
  });

export const createRuleForCompany = async (
  companySlug: string,
  data: Partial<api.UpsertContentFilterRuleRequest>,
) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.post<api.ContentFilterRule>(
      `/v1/companies/${companySlug}/content-filters/rules`,
      data,
    );
    return result.data;
  });

export const updateRuleForCompany = async (
  companySlug: string,
  id: string,
  data: Partial<api.UpsertContentFilterRuleRequest>,
) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.put<api.ContentFilterRule>(
      `/v1/companies/${companySlug}/content-filters/rules/${id}`,
      data,
    );
  });

export const deleteRuleForCompany = async (companySlug: string, id: string) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete<void>(
      `/v1/companies/${companySlug}/content-filters/rules/${id}`,
    );
  });

export const fetchControllerState = async (controllerName: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<ControllerStateResponse>(
      `/v1/controllers/${controllerName}/state`,
    );
    return result.data;
  });
