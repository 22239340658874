import NotFound from '../../components/NotFound';

export const Meta = () => ({
  path: '*',
  layout: null,
});

export function NotFoundPage() {
  return <NotFound />;
}

export default NotFoundPage;
