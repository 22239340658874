import type { AccessLevel } from '@meterup/atto';
import { isDefinedAndNotEmpty } from '@meterup/common';
import { useMemo } from 'react';

import { useGetAccessLevelForUser } from '../components/HasAccess/useAccessLevelForUser';
import { PermissionType } from '../gql/graphql';
import { useCurrentCompanyOrDefault } from '../providers/CurrentCompanyProvider';
import { useCurrentCompanyContracts } from './useContracts';
import { useDefaultNetworkForCompany } from './useNetworksForCompany';

export enum ProductTypes {
  AUTO_VPN = 'auto-vpn',
  COMMAND = 'command',
  CONNECT = 'connect',
  NETWORK = 'network',
  TUNNEL = 'tunnel',
}

export interface UserProductsAccess {
  isCustomerOfProduct(productType: ProductTypes): boolean;
  accessLevel(productType: ProductTypes): AccessLevel | undefined | null;
  shouldSeeProduct(productType: ProductTypes): boolean;
}

type ProductAccessMap = {
  [key in ProductTypes]: {
    isCustomer: boolean;
    accessLevel?: AccessLevel | null;
    adminOverride?: boolean;
  };
};

class UserProductsAccessImpl implements UserProductsAccess {
  constructor(private readonly productAccessMap: ProductAccessMap) {}

  isCustomerOfProduct(productType: ProductTypes): boolean {
    return this.productAccessMap[productType].isCustomer;
  }

  accessLevel(productType: ProductTypes): AccessLevel | undefined | null {
    return this.productAccessMap[productType].accessLevel;
  }

  shouldSeeProduct(productType: ProductTypes): boolean {
    return (
      (this.isCustomerOfProduct(productType) && !!this.accessLevel(productType)) ||
      (this.productAccessMap[productType].adminOverride ?? false)
    );
  }
}

export function useUserProductsAccess() {
  const contracts = useCurrentCompanyContracts();
  const companySlug = useCurrentCompanyOrDefault();
  const defaultNetwork = useDefaultNetworkForCompany(companySlug);
  const getAccessLevel = useGetAccessLevelForUser();

  return useMemo(
    () =>
      new UserProductsAccessImpl({
        [ProductTypes.AUTO_VPN]: {
          isCustomer: isDefinedAndNotEmpty(defaultNetwork),
          accessLevel: getAccessLevel({ permissions: PermissionType.PermAutoVpnRead }),
        },
        [ProductTypes.COMMAND]: {
          isCustomer: isDefinedAndNotEmpty(defaultNetwork),
          accessLevel: getAccessLevel({
            featureFlags: 'command-dock-product',
          }),
        },
        [ProductTypes.CONNECT]: {
          isCustomer: contracts.length > 0,
          accessLevel: getAccessLevel({ permissions: PermissionType.PermCompanyUpdate }),
        },
        [ProductTypes.NETWORK]: {
          isCustomer: isDefinedAndNotEmpty(defaultNetwork),
          accessLevel: getAccessLevel({ permissions: PermissionType.PermNetworkRead }),
        },
        [ProductTypes.TUNNEL]: {
          isCustomer: false,
          accessLevel: getAccessLevel({
            featureFlags: 'tunnel-ui',
          }),
        },
      }),
    [contracts, defaultNetwork, getAccessLevel],
  );
}
