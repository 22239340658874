import type { MeterLDFlags } from '../../feature_flags';
import type {
  IpForwardingRuleQuery,
  IpForwardingRulesQuery,
  PolicyRoutingRulesForNetworkQuery,
  StaticRoutesForNetworkQuery,
  VlaNsStaticMappingsRulesQuery,
} from '../../gql/graphql';
import { graphql } from '../../gql';
import { PermissionType } from '../../gql/graphql';
import { NosFeature } from '../../hooks/useNosFeatures';

export enum RoutingTab {
  RoutingTable = 'routing-table',
  PolicyRouting = 'policy-routing',
  StaticRoutes = 'static-routes',
  IPForwarding = 'ip-forwarding',
}

export const tabPermissions: Record<RoutingTab, PermissionType> = {
  [RoutingTab.RoutingTable]: PermissionType.PermNetworkRouteRead,
  [RoutingTab.PolicyRouting]: PermissionType.PermPolicyRoutingRead,
  [RoutingTab.StaticRoutes]: PermissionType.PermNetworkRouteRead,
  [RoutingTab.IPForwarding]: PermissionType.PermIpForwardingRead,
} as const;

export const tabNosFeatures: Partial<Record<RoutingTab, NosFeature>> = {
  [RoutingTab.IPForwarding]: NosFeature.COS_IP_FORWARDING,
} as const;

export const tabFeatureFlags: Partial<Record<RoutingTab, keyof MeterLDFlags>> = {
  [RoutingTab.IPForwarding]: 'ip-forwarding',
} as const;

graphql(`
  fragment PolicyRoutingRulesFields on PolicyRoutingRule {
    UUID
    name
    isEnabled
    srcPrefix
    srcVLAN {
      __typename
      UUID
      name
      ipV4ClientAssignmentProtocol
      ipV4ClientGateway
      ipV4ClientPrefixLength
    }
    dstPrefix
    srcPortRanges {
      lower
      upper
    }
    dstPortRanges {
      lower
      upper
    }
    protocols
    bindings {
      UUID
      phyInterface {
        __typename
        ...PhyInterfaceLabelFields
        isUplinkActive
        hasWANActivity
        isEnabled
      }
      metric
    }
  }
`);

export const policyRoutingRulesForNetworkQuery = graphql(`
  query PolicyRoutingRulesForNetwork($networkUUID: UUID!) {
    policyRoutingRulesForNetwork(networkUUID: $networkUUID) {
      ...PolicyRoutingRulesFields
    }
  }
`);

export const policyRoutingRuleQuery = graphql(`
  query PolicyRoutingRule($uuid: UUID!) {
    policyRoutingRule(UUID: $uuid) {
      ...PolicyRoutingRulesFields
    }
  }
`);

export const createPolicyRoutingRuleMutation = graphql(`
  mutation CreatePolicyRoutingRule($networkUUID: UUID!, $input: CreatePolicyRoutingRuleInput!) {
    createPolicyRoutingRule(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updatePolicyRoutingRuleMutation = graphql(`
  mutation UpdatePolicyRoutingRule($uuid: UUID!, $input: UpdatePolicyRoutingRuleInput!) {
    updatePolicyRoutingRule(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deletePolicyRoutingRuleMutation = graphql(`
  mutation DeletePolicyRoutingRule($uuid: UUID!) {
    deletePolicyRoutingRule(UUID: $uuid) {
      UUID
    }
  }
`);

export const updateBindingsForPolicyRoutingRuleMutation = graphql(`
  mutation UpdatePolicyRoutingRuleBindings(
    $input: BindPolicyRoutingRulesInput!
    $orderedRuleUUIDs: [UUID!]!
  ) {
    bindPolicyRoutingRules(input: $input, orderedRuleUUIDs: $orderedRuleUUIDs) {
      UUID
      metric
    }
  }
`);

export type PolicyRoutingRule =
  PolicyRoutingRulesForNetworkQuery['policyRoutingRulesForNetwork'][number];

export type PolicyRoutingRuleBinding = NonNullable<PolicyRoutingRule['bindings']>[number] & {
  rule: PolicyRoutingRule;
};

export type PolicyRoutingRulePhyInterface = NonNullable<
  PolicyRoutingRuleBinding['phyInterface']
> & {
  bindings: PolicyRoutingRuleBinding[];
};

export type PolicyRoutingRuleInterface = PolicyRoutingRulePhyInterface;

export type InterfaceBindings = Record<string, PolicyRoutingRuleInterface>;

graphql(`
  fragment StaticRouteFields on StaticRoute {
    UUID
    name
    isEnabled
    dstPrefix

    gatewayIP
    gatewayDHCPStaticMapping {
      UUID
      name
      macAddress
      ipAddress
      hostname
    }
  }
`);

export const staticRoutesForNetworkQuery = graphql(`
  query StaticRoutesForNetwork($networkUUID: UUID!) {
    staticRoutesForNetwork(networkUUID: $networkUUID) {
      ...StaticRouteFields
    }
  }
`);

export const staticRouteQuery = graphql(`
  query StaticRoute($uuid: UUID!) {
    staticRoute(UUID: $uuid) {
      ...StaticRouteFields
    }
  }
`);

export const createStaticRouteMutation = graphql(`
  mutation CreateStaticRoute($networkUUID: UUID!, $input: CreateStaticRouteInput!) {
    createStaticRoute(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateStaticRouteMutation = graphql(`
  mutation UpdateStaticRoute($uuid: UUID!, $input: UpdateStaticRouteInput!) {
    updateStaticRoute(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteStaticRouteMutation = graphql(`
  mutation DeleteStaticRoute($uuid: UUID!) {
    deleteStaticRoute(UUID: $uuid) {
      UUID
    }
  }
`);

export type StaticRoute = StaticRoutesForNetworkQuery['staticRoutesForNetwork'][number];

export const vlansStaticMappingsRulesQuery = graphql(`
  query VLANsStaticMappingsRules($networkUUID: UUID!) {
    vlans(networkUUID: $networkUUID) {
      UUID
      name
      ipV4ClientAssignmentProtocol
      ipV4ClientGateway
      ipV4ClientPrefixLength

      dhcpRule {
        UUID
        staticMappings {
          UUID
          name
          macAddress
          ipAddress
          hostname
        }
      }
    }
  }
`);

export type VLANStaticMappingsRule = VlaNsStaticMappingsRulesQuery['vlans'][number];

export function interfaceSupportsPolicyRoutingRules(policyRoutingRuleInterface: {
  isUplink: boolean;
}): boolean {
  return policyRoutingRuleInterface.isUplink;
}

// Explicitly using return type to check exhaustiveness
// eslint-disable-next-line consistent-return
export function labelForBindingType(bindingType: PolicyRoutingRuleInterface['__typename']): string {
  switch (bindingType) {
    case 'PhyInterface':
      return 'WAN';
  }
}

export type DHCPStaticMapping = NonNullable<
  VLANStaticMappingsRule['dhcpRule']
>['staticMappings'][number];

export function labelForStaticMapping(staticMapping: DHCPStaticMapping): string {
  if (!staticMapping.name) return staticMapping.macAddress;

  return `${staticMapping.name} (${staticMapping.macAddress})`;
}

export const ipForwardingRulesQuery = graphql(`
  query IPForwardingRules($networkUUID: UUID!) {
    ipForwardingRulesForNetwork(networkUUID: $networkUUID) {
      UUID
      name
      isEnabled
      uplinkPhyInterface {
        UUID
        ...PhyInterfaceLabelFields
      }
      vlan {
        UUID
        name
      }
    }
  }
`);

export const ipForwardingRuleQuery = graphql(`
  query IPForwardingRule($uuid: UUID!) {
    ipForwardingRule(UUID: $uuid) {
      UUID
      name
      isEnabled
      uplinkPhyInterface {
        UUID
        ...PhyInterfaceLabelFields
      }
      vlan {
        UUID
        name
      }
    }
  }
`);

export const createIPForwardingRuleMutation = graphql(`
  mutation CreateIPForwardingRule($networkUUID: UUID!, $input: CreateIPForwardingRuleInput!) {
    createIPForwardingRule(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateIPForwardingRuleMutation = graphql(`
  mutation UpdateIPForwardingRule($uuid: UUID!, $input: UpdateIPForwardingRuleInput!) {
    updateIPForwardingRule(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteIPForwardingRuleMutation = graphql(`
  mutation DeleteIPForwardingRule($uuid: UUID!) {
    deleteIPForwardingRule(UUID: $uuid) {
      UUID
    }
  }
`);

export type IPForwardingRulesResult = IpForwardingRulesQuery['ipForwardingRulesForNetwork'][number];
export type IPForwardingRule = IpForwardingRuleQuery['ipForwardingRule'];
