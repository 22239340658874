import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

export default function ResetErrorOnNavigation({ resetError }: { resetError: () => void }) {
  const location = useLocation();
  const locationRef = useRef<string>(location.pathname);

  useEffect(() => {
    if (resetError && location.pathname !== locationRef.current) {
      resetError();
    }
    locationRef.current = location.pathname;
  }, [resetError, location.pathname]);

  return null;
}
