import { useIsOperator } from '@meterup/authorization';
import { OPERATOR_ACTIONS_GROUP_NAME, useCommand, useRegisterCommands } from '@meterup/command';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';

import type { HardwareDeviceForNavigationQuery } from '../../gql/graphql';
import { paths } from '../../constants';
import { graphql } from '../../gql';
import { DeviceType, VirtualDeviceType } from '../../gql/graphql';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { deviceTypeToHardwareIconPropHardware } from '../../utils/types';
import { getLastUsedTab } from '../Devices/lastUsedTabs';
import { AccessPointsTab } from '../Hardware/AccessPoints/enums';
import { PDUsTab } from '../Hardware/PowerDistributionUnits/enums';
import { SecurityAppliancesTab } from '../Hardware/SecurityAppliance/enums';
import { SwitchesTab } from '../Hardware/Switches/enums';

const hardwareDeviceForNavigationQuery = graphql(`
  query HardwareDeviceForNavigation($serialNumber: String!) {
    hardwareDevice(serialNumber: $serialNumber) {
      serialNumber
      deviceType
      deviceModel

      virtualDevice {
        UUID
        label
        description

        network {
          UUID
          slug
          companySlug
        }
      }
      spareDeviceNetwork {
        UUID
        slug
        companySlug
      }
    }
  }
`);

type HardwareDeviceResult = HardwareDeviceForNavigationQuery['hardwareDevice'];

function getPathForHardwareDevice(hardwareDevice: HardwareDeviceResult): string | undefined {
  if (hardwareDevice.virtualDevice?.network.companySlug) {
    switch (hardwareDevice.deviceType) {
      case DeviceType.AccessPoint:
        return makeLink(paths.pages.AccessPointPage, {
          companyName: hardwareDevice.virtualDevice.network.companySlug,
          networkSlug: hardwareDevice.virtualDevice.network.slug,
          uuid: hardwareDevice.virtualDevice.UUID,
          tab: getLastUsedTab(VirtualDeviceType.AccessPoint),
        });
      case DeviceType.Controller:
        return makeLink(paths.pages.SecurityApplianceDetailPage, {
          companyName: hardwareDevice.virtualDevice.network.companySlug,
          networkSlug: hardwareDevice.virtualDevice.network.slug,
          uuid: hardwareDevice.virtualDevice.UUID,
          tab: getLastUsedTab(VirtualDeviceType.Controller),
        });
      case DeviceType.PowerDistributionUnit:
        return makeLink(paths.pages.PowerDistributionUnitDetailPage, {
          companyName: hardwareDevice.virtualDevice.network.companySlug,
          networkSlug: hardwareDevice.virtualDevice.network.slug,
          uuid: hardwareDevice.virtualDevice.UUID,
          tab: getLastUsedTab(VirtualDeviceType.PowerDistributionUnit),
        });
      case DeviceType.Switch:
        return makeLink(paths.pages.SwitchDetailPage, {
          companyName: hardwareDevice.virtualDevice.network.companySlug,
          networkSlug: hardwareDevice.virtualDevice.network.slug,
          uuid: hardwareDevice.virtualDevice.UUID,
          tab: getLastUsedTab(VirtualDeviceType.Switch),
        });
    }
  }

  if (hardwareDevice.spareDeviceNetwork?.companySlug) {
    switch (hardwareDevice.deviceType) {
      case DeviceType.AccessPoint:
        return makeLink(paths.pages.AccessPointsListPage, {
          companyName: hardwareDevice.spareDeviceNetwork.companySlug,
          networkSlug: hardwareDevice.spareDeviceNetwork.slug,
          tab: AccessPointsTab.Spares,
        });
      case DeviceType.Controller:
        return makeLink(paths.pages.SecurityAppliancesListPage, {
          companyName: hardwareDevice.spareDeviceNetwork.companySlug,
          networkSlug: hardwareDevice.spareDeviceNetwork.slug,
          tab: SecurityAppliancesTab.Spares,
        });
      case DeviceType.PowerDistributionUnit:
        return makeLink(paths.pages.PowerDistributionUnitListPage, {
          companyName: hardwareDevice.spareDeviceNetwork.companySlug,
          networkSlug: hardwareDevice.spareDeviceNetwork.slug,
          tab: PDUsTab.Spares,
        });
      case DeviceType.Switch:
        return makeLink(paths.pages.SwitchesPage, {
          companyName: hardwareDevice.spareDeviceNetwork.companySlug,
          networkSlug: hardwareDevice.spareDeviceNetwork.slug,
          tab: SwitchesTab.Spares,
        });
    }
  }

  return undefined;
}

export function useNavigateToSerialNumberCommand() {
  const isOperator = useIsOperator();
  const { state } = useCommand();
  const navigate = useNavigate();

  const [searchQuery] = useDebounce(state?.ui?.search ?? '', 250);

  const hardwareDevice = useGraphQL(
    hardwareDeviceForNavigationQuery,
    { serialNumber: searchQuery },
    {
      suspense: false,
      useErrorBoundary: false,
      enabled: !!searchQuery,
    },
  ).data?.hardwareDevice;

  const { nodeFactory } = state;

  const nodes = useMemo(() => {
    const path = hardwareDevice ? getPathForHardwareDevice(hardwareDevice) : null;
    if (isOperator && hardwareDevice && path) {
      return [
        nodeFactory.action({
          id: 'navigate-to-serial-number',
          group: OPERATOR_ACTIONS_GROUP_NAME,
          display: `Navigate to serial number ${hardwareDevice.serialNumber}`,
          label: `Navigate to serial number ${hardwareDevice.serialNumber}`,
          icon: deviceTypeToHardwareIconPropHardware(hardwareDevice.deviceType),
          internal: true,
          async onSelect() {
            navigate(path);
          },
        }),
      ];
    }

    return [];
  }, [isOperator, hardwareDevice, navigate, nodeFactory]);

  useRegisterCommands(nodes, [hardwareDevice, navigate, isOperator]);
}
