import type { ZodSchema } from 'zod';
import { z } from 'zod';

import { VirtualDeviceType } from '../../gql/graphql';
import { getLocalStorage, useLocalStorage } from '../../hooks/useLocalStorage';
import { AccessPointTab } from '../Hardware/AccessPoints/enums';
import { ObserverTab } from '../Hardware/Observers/enums';
import { PDUTab } from '../Hardware/PowerDistributionUnits/enums';
import { SecurityApplianceTab } from '../Hardware/SecurityAppliance/enums';
import { SwitchTab } from '../Hardware/Switches/enums';

interface LastUsedTabDef<T> {
  key: string;
  default: T;
  schema: ZodSchema<T>;
}

const DEFS = {
  [VirtualDeviceType.PowerDistributionUnit]: {
    key: 'pdu-last-tab',
    default: PDUTab.Insights,
    schema: z.nativeEnum(PDUTab),
  },
  [VirtualDeviceType.Controller]: {
    key: 'security-appliance-last-tab',
    default: SecurityApplianceTab.Insights,
    schema: z.nativeEnum(SecurityApplianceTab),
  },
  [VirtualDeviceType.Switch]: {
    key: 'switch-last-tab',
    default: SwitchTab.Insights,
    schema: z.nativeEnum(SwitchTab),
  },
  [VirtualDeviceType.AccessPoint]: {
    key: 'access-point-last-tab',
    default: AccessPointTab.Insights,
    schema: z.nativeEnum(AccessPointTab),
  },
  [VirtualDeviceType.Observer]: {
    key: 'observer-last-tab',
    default: ObserverTab.Tests,
    schema: z.nativeEnum(ObserverTab),
  },
} as const satisfies Record<VirtualDeviceType, LastUsedTabDef<unknown>>;

type DefReturnType<V extends VirtualDeviceType> = z.infer<(typeof DEFS)[V]['schema']>;

export function useLastUsedTab<V extends VirtualDeviceType>(
  deviceType: V,
): [DefReturnType<V>, (value: DefReturnType<V>) => void] {
  const def = DEFS[deviceType];

  return useLocalStorage(def.key, def.default as DefReturnType<V>, {
    schema: def.schema,
  });
}

export function getLastUsedTab<V extends VirtualDeviceType>(deviceType: V): DefReturnType<V> {
  const def = DEFS[deviceType];

  return getLocalStorage(def.key, def.default as DefReturnType<V>, { schema: def.schema });
}
