import { useContext, useEffect } from 'react';

import { useNetworkOrNull } from '../hooks/useNetworkFromPath';
import { DefaultNetworkContext } from '../providers/DefaultNetworkProvider';

export function AutoSaveDefaultNetworkEffect() {
  const network = useNetworkOrNull();
  const { setDefaultNetworkUUID } = useContext(DefaultNetworkContext);

  useEffect(() => {
    if (network) {
      setDefaultNetworkUUID(network.UUID);
    }
  }, [network, setDefaultNetworkUUID]);

  return null;
}
