export enum PDUsTab {
  Current = 'list',
  Spares = 'spares',
  ConfigHistory = 'config-history',
}

export enum PDUTab {
  BootHistory = 'boot-history',
  ConfigHistory = 'config-history',
  Insights = 'insights',
  Outlets = 'outlets',
}

export enum PDUEditTab {
  Config = 'config',
  Hardware = 'hardware',
}
