import posthog from 'posthog-js';

export const initializePosthog = () => {
  posthog.init(import.meta.env.POSTHOG_TOKEN, {
    api_host: `${window.location.origin}/posthog`,
    ui_host: 'https://us.posthog.com',
    loaded: (ph) => {
      if (import.meta.env.DEV) {
        ph.opt_out_capturing();
      }
    },
  });
};
