export enum AccessPointsTab {
  BSSIDs = 'bssids',
  Current = 'list',
  Insights = 'insights',
  Spares = 'spares',
  ACS = 'acs',
  ConfigHistory = 'config-history',
}

export enum AccessPointTab {
  BSSIDs = 'bssids',
  BootHistory = 'boot-history',
  Clients = 'clients',
  ConfigHistory = 'config-history',
  ConnectionEvents = 'connection-events',
  Insights = 'insights',
  ScanData = 'scan-data',
}

export enum AccessPointDrawerTab {
  AccessPoint = 'access-point',
  Clients = 'clients',
  Edit = 'edit',
}

export enum AccessPointEditTab {
  Config = 'config',
  Hardware = 'hardware',
}
