import { useGraphQL } from '@meterup/graphql';

import { useNetworkOrNull } from '../../../../hooks/useNetworkFromPath';
import { useCurrentCompanyOrDefault } from '../../../../providers/CurrentCompanyProvider';
import { useIdentity } from '../../../../providers/IdentityDataProvider';
import { nosVersionQuery } from '../../../NOS/utils';

export enum WorkbenchTab {
  Inspector = 'inspector',
  Network = 'network',
  Notes = 'notes',
}

export function DebugData() {
  const identity = useIdentity();
  const companyName = useCurrentCompanyOrDefault();
  const network = useNetworkOrNull();
  const nosVersion = useGraphQL(nosVersionQuery, { id: network?.pinnedNOSVersionID ?? 0 }).data
    ?.nosVersions?.[0];

  return `\`\`\`
URL: ${window.location.href}
User: ${identity.username} (${identity.first_name} ${identity.last_name})
Company: ${companyName}
Network: ${network?.label || 'Unknown'}
NOS: ${nosVersion?.version || 'Unknown'} (${nosVersion?.major}.${nosVersion?.minor}.${nosVersion?.patch})
Timestamp: ${new Date().toLocaleString()}
\`\`\`
`;
}

export function BugReportTemplate() {
  const description = `### What happened?
‌‌ 
### Where did this happen?
‌‌ 
### Debug data
${DebugData()}`;

  return `https://linear.app/team/DASH/new?template=cb0356a2-2b9b-430b-b581-d1af603f556e&status=Triage&label=From%20Workbench&description=${encodeURIComponent(description)}`;
}
