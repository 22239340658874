import type { DisplayableError } from '@meterup/common';
import type { FallbackRender } from '@sentry/react';
import {
  Body,
  Box,
  Button,
  colors,
  darkThemeSelector,
  Dialog,
  DialogContent,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerHeader,
  EmptyState,
  Heading,
  Link,
  PaneFooter,
  useDialogState,
} from '@meterup/atto';
import { isDisplayableError, NoConnectionError } from '@meterup/common';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

import type { ErrorFallbackProps } from './utils';
import { paths, SUPPORT_URL } from '../../constants';
import { isNotFoundError } from '../../errors';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { styled } from '../../stitches';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { GlobalMaintenanceScreen } from '../GlobalMaintenanceKillswitch';
import NotFound from '../NotFound';
import { DevelopmentErrorFallback } from './DevelopmentErrorFallback';
import ResetErrorOnNavigation from './ResetErrorOnNavigation';

const ErrorFallbackContainer = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
  width: '100vw',
  height: '100dvh',
  padding: '$20',
  backgroundColor: colors.bgApplicationLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
  },
});
ErrorFallbackContainer.displayName = 'ErrorFallbackContainer';

const ErrorFallbackPosition = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'center',
  width: '100%',
  height: '100%',
  maxWidth: '500px',

  '@maxSm': {
    paddingTop: '40%',
  },

  '@sm': {
    paddingTop: '12%',
  },
});
ErrorFallbackPosition.displayName = 'ErrorFallbackPosition';

export function RefreshOrContactSupportBoilerplate() {
  return (
    <>
      <Body>
        Our team has been notified and is working to resolve the issue as soon as possible. Please
        try again, if the issue persists please <Link href={SUPPORT_URL}>contact support</Link>.
      </Body>
      <Body>
        Your local network and internet connection (including Wi-Fi) are not affected and will
        continue functioning normally.
      </Body>
    </>
  );
}

export function ErrorFallback(props: ErrorFallbackProps) {
  const { error, resetError } = props;
  const title = isDisplayableError(error) ? error.displayTitle : 'Something went wrong';
  const message = isDisplayableError(error) ? (
    error.displayMessage
  ) : (
    <>
      An unexpected error occurred. <RefreshOrContactSupportBoilerplate />
    </>
  );

  if (import.meta.env.NODE_ENV === 'development') {
    return <DevelopmentErrorFallback {...props} />;
  }

  return (
    <ErrorFallbackContainer>
      <ErrorFallbackPosition>
        <ResetErrorOnNavigation resetError={resetError} />
        <EmptyState
          heading={title}
          subheading={message}
          action={
            <Button
              as="a"
              href={makeLink(paths.pages.RootPage, {})}
              arrangement="leading-icon"
              icon="home"
            >
              Back to the Meter Dashboard
            </Button>
          }
        />
      </ErrorFallbackPosition>
    </ErrorFallbackContainer>
  );
}

export function ErrorBoundary({
  onReset,
  children,
  fallback = ErrorFallback,
}: {
  onReset?: () => void;
  children: React.ReactNode;
  fallback?: React.ReactElement | FallbackRender | undefined;
}) {
  return (
    <SentryErrorBoundary onReset={onReset} fallback={fallback}>
      {children}
    </SentryErrorBoundary>
  );
}

function UnexpectedErrorFallbackDrawer({
  componentStack,
  error,
  eventId,
  resetError,
}: ErrorFallbackProps) {
  return (
    <Drawer>
      <ResetErrorOnNavigation resetError={resetError} />
      <DrawerHeader heading="Unexpected error" onClose={useCloseDrawerCallback()} />
      <DrawerContent gutter="all">
        {import.meta.env.NODE_ENV === 'development' ? (
          <DevelopmentErrorFallback
            error={error}
            componentStack={componentStack}
            resetError={resetError}
            eventId={eventId}
          />
        ) : (
          <ErrorFallbackContainer>
            <EmptyState
              heading="Unexpected error"
              subheading={
                <>
                  An unexpected error has occurred. Please try refreshing the page. If the issue
                  persists, please <Link href="https://www.meter.com/support">contact support</Link>
                  .
                </>
              }
            />
          </ErrorFallbackContainer>
        )}
      </DrawerContent>
    </Drawer>
  );
}

function DisplayableErrorFallbackDrawer({
  componentStack,
  error,
  eventId,
  resetError,
}: ErrorFallbackProps & { error: DisplayableError }) {
  return (
    <Drawer>
      <ResetErrorOnNavigation resetError={resetError} />
      <DrawerHeader heading={error.displayTitle} onClose={useCloseDrawerCallback()} />
      <DrawerContent gutter="all">
        {import.meta.env.NODE_ENV === 'development' ? (
          <DevelopmentErrorFallback
            error={error}
            componentStack={componentStack}
            resetError={resetError}
            eventId={eventId}
          />
        ) : (
          <EmptyState heading={error.displayTitle} subheading={error.displayMessage} />
        )}
      </DrawerContent>
    </Drawer>
  );
}

export function ErrorFallbackDrawer(props: ErrorFallbackProps) {
  const { error } = props;
  return isDisplayableError(error) ? (
    <DisplayableErrorFallbackDrawer {...props} error={error} />
  ) : (
    <UnexpectedErrorFallbackDrawer {...props} />
  );
}

function DisplayableErrorFallbackDialog({
  componentStack,
  error,
  eventId,
  resetError,
}: ErrorFallbackProps & { error: DisplayableError }) {
  const { state } = useDialogState();
  return (
    <Dialog state={state}>
      <ResetErrorOnNavigation resetError={resetError} />
      <DialogHeader heading={error.displayTitle} onClose={state.close} />
      <DialogContent gutter="all">
        {import.meta.env.NODE_ENV === 'development' ? (
          <DevelopmentErrorFallback
            error={error}
            componentStack={componentStack}
            resetError={resetError}
            eventId={eventId}
          />
        ) : (
          <EmptyState heading={error.displayTitle} subheading={error.displayMessage} />
        )}
      </DialogContent>
    </Dialog>
  );
}

function UnexpectedErrorFallbackDialog({
  componentStack,
  error,
  eventId,
  resetError,
}: ErrorFallbackProps) {
  const { state } = useDialogState();
  return (
    <Dialog state={state}>
      <ResetErrorOnNavigation resetError={resetError} />
      <DialogHeader heading="Unexpected error" onClose={state.close} />
      <DialogContent gutter="all">
        {import.meta.env.NODE_ENV === 'development' ? (
          <DevelopmentErrorFallback
            error={error}
            componentStack={componentStack}
            resetError={resetError}
            eventId={eventId}
          />
        ) : (
          <ErrorFallbackContainer>
            <EmptyState
              heading="Unexpected error"
              subheading={
                <>
                  An unexpected error has occurred. Please try refreshing the page. If the issue
                  persists, please <Link href="https://www.meter.com/support">contact support</Link>
                  .
                </>
              }
            />
          </ErrorFallbackContainer>
        )}
      </DialogContent>
    </Dialog>
  );
}

export function ErrorFallbackDialog(props: ErrorFallbackProps) {
  const { error } = props;
  return isDisplayableError(error) ? (
    <DisplayableErrorFallbackDialog {...props} error={error} />
  ) : (
    <UnexpectedErrorFallbackDialog {...props} />
  );
}

function FooterBoilerplate() {
  return (
    <>
      <Body>
        Meter, Inc. • <Link href="https://www.meter.com/support">Support & FAQs</Link>
      </Body>
      <PaneFooter
        actions={
          <Button
            as="a"
            href={makeLink(paths.pages.RootPage, {})}
            variant="secondary"
            icon="home"
            arrangement="leading-icon"
          >
            Go to Dashboard home page
          </Button>
        }
      />
    </>
  );
}

export function FatalErrorFallback(props: ErrorFallbackProps) {
  const { error, resetError } = props;
  if (import.meta.env.NODE_ENV === 'development') {
    return <DevelopmentErrorFallback {...props} />;
  }

  if (error instanceof NoConnectionError) {
    return <GlobalMaintenanceScreen />;
  }

  if (isNotFoundError(error)) {
    return (
      <Box
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '$32',
            maxWidth: 400,
            alignItems: 'stretch',
          }}
        >
          <NotFound />
        </Box>
      </Box>
    );
  }

  const title = isDisplayableError(error) ? error.displayTitle : 'Something went wrong';
  const message = isDisplayableError(error)
    ? error.displayMessage
    : 'An unexpected error occurred.';

  return (
    <Box
      style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ResetErrorOnNavigation resetError={resetError} />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '$32',
          maxWidth: 400,
          alignItems: 'stretch',
        }}
      >
        <Box
          style={{ display: 'flex', flexDirection: 'column', gap: '$20', alignItems: 'flex-start' }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$4',
              alignItems: 'flex-start',
            }}
          >
            <Heading>{title}</Heading>
          </Box>
          <Body>{message}</Body>
          <RefreshOrContactSupportBoilerplate />
        </Box>
        <FooterBoilerplate />
      </Box>
    </Box>
  );
}
