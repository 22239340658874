import { DateTime, Duration } from 'luxon';
import { z } from 'zod';

export function secToHuman(sec: number, unitDisplay?: 'short' | 'long' | 'narrow') {
  return Duration.fromObject({ seconds: sec }).rescale().toHuman({ unitDisplay });
}

export const fromISOtoLocaleString = ({
  iso,
  format = DateTime.DATETIME_MED_WITH_SECONDS,
}: {
  iso: string;
  format?: Intl.DateTimeFormatOptions;
}) => DateTime.fromISO(iso).toLocaleString(format);

export function timestampToRelativeTime(timestamp: number) {
  return DateTime.fromMillis(timestamp).toRelative();
}

export function isoStringToRelativeTime(isoString: string) {
  return DateTime.fromISO(isoString).toRelative();
}

export function stripDateTimeForInput(dateTime: DateTime): string {
  return dateTime
    .set({
      second: 0,
      millisecond: 0,
    })
    .toISO({
      includeOffset: false,
      suppressSeconds: true,
      suppressMilliseconds: true,
    });
}

export function maybeParseDate(maybeDate: Date | string): Date {
  if (maybeDate instanceof Date) return maybeDate;
  return DateTime.fromISO(maybeDate).toJSDate();
}

export enum DurationUnit {
  Years = 'years',
  Months = 'months',
  Weeks = 'weeks',
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds',
  Milliseconds = 'milliseconds',
}

const DURATION_UNIT_VALUE_MESSAGE = 'Please provide a positive integer.';

export const durationSchema = z
  .object({
    years: z
      .number()
      .int({ message: DURATION_UNIT_VALUE_MESSAGE })
      .positive({ message: DURATION_UNIT_VALUE_MESSAGE })
      .optional(),
    months: z
      .number()
      .int({ message: DURATION_UNIT_VALUE_MESSAGE })
      .positive({ message: DURATION_UNIT_VALUE_MESSAGE })
      .optional(),
    days: z
      .number()
      .int({ message: DURATION_UNIT_VALUE_MESSAGE })
      .positive({ message: DURATION_UNIT_VALUE_MESSAGE })
      .optional(),
    hours: z
      .number()
      .int({ message: DURATION_UNIT_VALUE_MESSAGE })
      .positive({ message: DURATION_UNIT_VALUE_MESSAGE })
      .optional(),
    minutes: z
      .number()
      .int({ message: DURATION_UNIT_VALUE_MESSAGE })
      .positive({ message: DURATION_UNIT_VALUE_MESSAGE })
      .optional(),
    seconds: z
      .number()
      .int({ message: DURATION_UNIT_VALUE_MESSAGE })
      .positive({ message: DURATION_UNIT_VALUE_MESSAGE })
      .optional(),
    milliseconds: z
      .number()
      .int({ message: DURATION_UNIT_VALUE_MESSAGE })
      .positive({ message: DURATION_UNIT_VALUE_MESSAGE })
      .optional(),
  })
  .refine((obj) => Object.keys(obj).length > 0, {
    message: 'Please provide at least one unit of duration measurement.',
  })
  .refine((obj) => Duration.fromObject(obj).isValid, {
    message: 'Please provide a valid duration.',
  });
export type DurationObject = z.infer<typeof durationSchema>;
