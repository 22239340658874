import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { z } from 'zod';

import type {
  DnsFirewallRuleApplicationsQuery,
  DnsFirewallRuleCategoriesQuery,
  DnsFirewallRuleGroupsQuery,
  DnsFirewallRuleQuery,
} from '../../gql/graphql';
import { graphql } from '../../gql';
import {
  CreateApplicationDnsFirewallRuleInputSchema,
  FirewallRuleActionSchema,
} from '../../gql/zod-types';
import { isWildcardDomain } from '../../utils/fqdn';

export enum DNSSecurityTab {
  VLANs = 'vlans',
}

export const vlansAndDHCPRulesQuery = graphql(`
  query VLANsAndDHCPRules($networkUUID: UUID!) {
    vlans(networkUUID: $networkUUID) {
      __typename
      UUID
      name
      description
      isEnabled
      isInternal
      isDefault
      vlanID
      ipV4ClientAssignmentProtocol
      ipV4ClientGateway
      ipV4ClientPrefixLength
      dhcpRule {
        UUID
        dnsUseGatewayProxy
      }
    }
  }
`);

export const dnsFirewallRulesQuery = graphql(`
  query DNSFirewallRulesForNetwork($networkUUID: UUID!) {
    applicationDNSFirewallRulesForNetwork(networkUUID: $networkUUID) {
      UUID
      isEnabled
      name
      action
      priority
      domain
      category {
        id
        name
        description
        group {
          id
          name
        }
      }
      group {
        id
        name
      }
      application {
        id
        name
      }
      boundDHCPRules {
        UUID
        vlan {
          UUID
          name
        }
      }
    }
  }
`);

export const dnsFirewallRuleQuery = graphql(`
  query DNSFirewallRule($uuid: UUID!) {
    applicationDNSFirewallRule(UUID: $uuid) {
      UUID
      isEnabled
      name
      action
      priority
      domain
      category {
        id
        name
        description
        group {
          id
          name
        }
      }
      group {
        id
        name
      }
      application {
        id
        name
      }
      boundDHCPRules {
        UUID
        vlan {
          UUID
          name
        }
      }
    }
  }
`);

export const createDNSFirewallRuleMutation = graphql(`
  mutation CreateDNSFirewallRule(
    $networkUUID: UUID!
    $input: CreateApplicationDNSFirewallRuleInput!
  ) {
    createApplicationDNSFirewallRule(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDNSFirewallRuleMutation = graphql(`
  mutation UpdateDNSFirewallRule($uuid: UUID!, $input: UpdateApplicationDNSFirewallRuleInput!) {
    updateApplicationDNSFirewallRule(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDNSFirewallRuleMutation = graphql(`
  mutation DeleteDNSFirewallRule($uuid: UUID!) {
    deleteApplicationDNSFirewallRule(UUID: $uuid) {
      UUID
    }
  }
`);

export const updateDNSFirewallRulesMutation = graphql(`
  mutation UpdateDNSFirewallRules($inputs: [UpdateApplicationDNSFirewallRulesInput!]!) {
    updateApplicationDNSFirewallRules(inputs: $inputs) {
      UUID
      priority
    }
  }
`);

export type DNSFirewallRule = DnsFirewallRuleQuery['applicationDNSFirewallRule'];

export const dnsFirewallRuleCategoriesQuery = graphql(`
  query DNSFirewallRuleCategories {
    applicationDNSFirewallRuleCategories {
      id
      name
      description
      group {
        id
        name
      }
    }
  }
`);

export const dnsFirewallRuleGroupsQuery = graphql(`
  query DNSFirewallRuleGroups {
    applicationDNSFirewallRuleGroups {
      id
      name
    }
  }
`);

export const dnsFirewallRuleApplicationsQuery = graphql(`
  query DNSFirewallRuleApplications {
    applicationDNSFirewallRuleApplications {
      id
      name
    }
  }
`);

export const dnsDomainApplicationQuery = graphql(`
  query DNSDomainApplication($hostname: String!) {
    applicationDNSFirewallRuleApplicationForHostname(hostname: $hostname) {
      id
      isVisible
      name
    }
  }
`);

export const dnsDomainCategoryQuery = graphql(`
  query DNSDomainCategory($hostname: String!) {
    applicationDNSFirewallRuleCategoryForHostname(hostname: $hostname) {
      id
      name
      description
      group {
        id
        name
      }
    }
  }
`);

export type DNSFirewallRuleApplication =
  DnsFirewallRuleApplicationsQuery['applicationDNSFirewallRuleApplications'][number];

export type DNSFirewallRuleCategory =
  DnsFirewallRuleCategoriesQuery['applicationDNSFirewallRuleCategories'][number];

export type DNSFirewallRuleGroup =
  DnsFirewallRuleGroupsQuery['applicationDNSFirewallRuleGroups'][number];

export function useDNSFirewallRuleCategories() {
  const categories = useGraphQL(dnsFirewallRuleCategoriesQuery)?.data
    ?.applicationDNSFirewallRuleCategories;
  return useMemo(() => categories ?? [], [categories]);
}

export function useDNSFirewallRuleGroups() {
  const groups = useGraphQL(dnsFirewallRuleGroupsQuery)?.data?.applicationDNSFirewallRuleGroups;
  return useMemo(() => groups ?? [], [groups]);
}

export function useDNSFirewallRuleApplications() {
  const applications = useGraphQL(dnsFirewallRuleApplicationsQuery)?.data
    ?.applicationDNSFirewallRuleApplications;
  return useMemo(() => applications ?? [], [applications]);
}

export enum DNSFirewallRuleKind {
  Application = 'application',
  Category = 'category',
  Domain = 'domain',
}

export const editDNSFirewallRuleInputSchema = CreateApplicationDnsFirewallRuleInputSchema.omit({
  // Categories and groups both use categoryID field
  groupID: true,
})
  .extend({
    boundDHCPRuleUUIDs: z
      .array(z.string())
      .nonempty({ message: 'Please select a VLAN with DHCP enabled.' }),
    name: z.string().nonempty({ message: 'Please provide a name.' }),
    ruleKind: z.nativeEnum(DNSFirewallRuleKind),
    // Categories and groups both use categoryID field
    categoryID: z.number().int({ message: 'Please select a category.' }).nullish().or(z.literal(0)),
    applicationID: z
      .number()
      .int({ message: 'Please select an application.' })
      .nullish()
      .or(z.literal(0)),
    domain: z
      .string()
      .refine((domain) => !domain || isWildcardDomain(domain), {
        message: 'Please provide a wildcard domain.',
      })
      .nullish(),
    action: FirewallRuleActionSchema,
  })
  .superRefine((values, ctx) => {
    switch (values.ruleKind) {
      case DNSFirewallRuleKind.Application:
        if (!values.applicationID) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please select an application.',
            path: ['applicationID'],
          });
        }
        break;
      case DNSFirewallRuleKind.Category:
        if (!values.categoryID) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please select a category.',
            path: ['categoryID'],
          });
        }
        break;
      case DNSFirewallRuleKind.Domain:
        if (!values.domain) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a fully qualified domain name.',
            path: ['domain'],
          });
        }
        break;
    }
  });

export type DNSFirewallRuleFormValues = z.input<typeof editDNSFirewallRuleInputSchema>;

export function dnsFirewallRuleKindFromRule(
  rule: DNSFirewallRule,
): DNSFirewallRuleKind | undefined {
  if (rule.application) return DNSFirewallRuleKind.Application;
  if (rule.category || rule.group) return DNSFirewallRuleKind.Category;
  if (rule.domain) return DNSFirewallRuleKind.Domain;

  return undefined;
}

export function dnsFirewallRuleKindDisplay(rule: DNSFirewallRule): string {
  if (rule.application) return rule.application.name;
  if (rule.category) return rule.category.name;
  if (rule.domain) return rule.domain;
  if (rule.group) return rule.group.name;

  return '';
}
