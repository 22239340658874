import { colors, darkThemeSelector, lightThemeSelector, LoadingIcon, styled } from '@meterup/atto';

const FullscreenFallbackContainer = styled('div', {
  width: '100vw',
  height: '100dvh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.bgNeutralLight,

  '@prefersLight': {
    backgroundColor: colors.bgNeutralLight,
  },

  '@prefersDark': {
    backgroundColor: colors.bgNeutralDark,
  },

  [lightThemeSelector]: {
    backgroundColor: colors.bgNeutralLight,
  },

  [darkThemeSelector]: {
    backgroundColor: colors.bgNeutralDark,
  },
});

const FullscreenFallbackIcon = styled(LoadingIcon, {
  color: colors.iconNeutralDark,

  '@prefersLight': {
    color: colors.iconNeutralLight,
  },

  '@prefersDark': {
    color: colors.iconNeutralDark,
  },

  [lightThemeSelector]: {
    color: colors.iconNeutralLight,
  },

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },
});

export function FullscreenFallback() {
  return (
    <FullscreenFallbackContainer>
      <FullscreenFallbackIcon size={24} />
    </FullscreenFallbackContainer>
  );
}
