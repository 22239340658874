export enum SecurityAppliancesTab {
  Current = 'list',
  Spares = 'spares',
  ConfigHistory = 'config-history',
}

export enum SecurityApplianceTab {
  BootHistory = 'boot-history',
  ConfigHistory = 'config-history',
  Insights = 'insights',
  Ports = 'ports',
}

export enum SecurityApplianceEditTab {
  Config = 'config',
  Hardware = 'hardware',
}

export enum SecurityAppliancePortTab {
  Details = 'details',
  Config = 'config',
}
