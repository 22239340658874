import { Button, EmptyState } from '@meterup/atto';

import { paths } from '../constants';
import { makeLink } from '../utils/main_and_drawer_navigation';

export default function NotFound() {
  return (
    <EmptyState
      icon="attention"
      heading="Page not found"
      action={
        <Button
          as="a"
          href={makeLink(paths.pages.RootPage, {})}
          arrangement="leading-icon"
          icon="home"
        >
          Back to the Meter Dashboard
        </Button>
      }
    />
  );
}
