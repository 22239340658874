import type { SizingScale } from '@meterup/atto';
import { colors, darkThemeSelector, Icon, Small, styled } from '@meterup/atto';
import Gravatar from 'react-gravatar';

import { getInitials } from '../../utils/strings';

const SigilLogo = styled('div', {
  position: 'absolute',
  zIndex: 2,
  inset: 0,
  backgroundSize: 'cover',
});

const SigilAvatar = styled(Gravatar, {
  position: 'absolute',
  zIndex: 2,
  inset: 0,
  objectFit: 'cover',
});

const SigilInitials = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  color: colors.headingBrandDark,
  letterSpacing: '-0.05em',
  lineHeight: '$18',
  fontFamily: '$mono',
  fontWeight: '$bold',

  [darkThemeSelector]: {
    color: colors.headingBrandLight,
  },

  '@notDesktop': {
    fontSize: '$14',
  },

  '@desktop': {
    fontSize: '$12',
  },
});

const SigilContainer = styled('div', Small, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.iconBrandLight,
  userSelect: 'none',
  pointerEvents: 'none',

  [darkThemeSelector]: {
    backgroundColor: colors.iconBrandDark,
  },
});

export type SigilProps = {
  domain?: string | null;
  label?: string;
  identity?: string;
  size?: SizingScale;
  type?: 'organization' | 'user';
};

export function Sigil({ identity, label, type = 'user', size, domain }: SigilProps) {
  return (
    <SigilContainer
      family="monospace"
      css={{
        maskImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M20 10C20 6.58894 19.7675 2.74054 18.5175 1.48688C17.2675 0.233235 12.7907 0 10.0291 0C7.26743 0 2.79072 0.233235 1.5407 1.48688C0.290696 2.74054 0 6.58894 0 10C0 13.4111 0.2907 17.2595 1.5407 18.5131C2.79072 19.7668 7.26743 20 10.0291 20C12.7907 20 17.2674 19.7668 18.5174 18.5131C19.7675 17.2595 20 13.4111 20 10Z"/></svg>')`,
        maskSize: 'cover',
        width: `$${size}`,
        maxWidth: `$${size}`,
        minWidth: `$${size}`,
        height: `$${size}`,
        maxHeight: `$${size}`,
        minHeight: `$${size}`,
      }}
    >
      {identity && (
        <>
          {type === 'organization' && domain && (
            <SigilLogo
              style={{
                backgroundImage: `url('https://img.logo.dev/${domain}?token=pk_GDe5K-89QfSPAhlmDU8iWw&retina=true')`,
              }}
            />
          )}
          {type === 'user' && (
            <SigilAvatar
              default="blank"
              email={identity}
              css={{
                width: `$${size}`,
                maxWidth: `$${size}`,
                minWidth: `$${size}`,
                height: `$${size}`,
                maxHeight: `$${size}`,
                minHeight: `$${size}`,
              }}
            />
          )}
        </>
      )}
      <SigilInitials>
        {label ? (
          getInitials(label, 2)
        ) : (
          <Icon icon={type === 'organization' ? 'company' : 'user'} size={10} />
        )}
      </SigilInitials>
    </SigilContainer>
  );
}
