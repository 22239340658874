import { type UseStorageOptions } from '@meterup/common';
import {
  getLocalStorage as getBaseLocalStorage,
  useLocalStorage as useBaseLocalStorage,
} from '@meterup/common';

import { logError } from '../utils/logError';

export function getLocalStorage<T>(
  key: string,
  initialValue: T,
  { schema }: Pick<UseStorageOptions<T>, 'schema'>,
) {
  const value = getBaseLocalStorage(key, initialValue, { logError, schema });

  if (!schema) {
    return value;
  }

  try {
    return schema.parse(value);
  } catch (err) {
    logError(err);
  }

  return initialValue;
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  { poll, schema }: Exclude<UseStorageOptions<T>, 'logError'> = {},
) {
  return useBaseLocalStorage(key, initialValue, { poll, logError, schema });
}
