import type { ExtractRouteParams } from '@meterup/react-router-extensions';
import type { Pathname, Search } from 'history';
import { generatePath } from 'react-router';

import { Nav } from '../nav';

export function makeLink<T extends string>(path: T, args: ExtractRouteParams<T, string>): string {
  // @ts-expect-error
  return generatePath(path, args);
}

export type PathAndSearch = {
  pathname: Pathname;
  search: Search;
};

function mergeSearchParams(a: URLSearchParams, b: Record<string, string | null | undefined>) {
  const newSearchParams = new URLSearchParams(a);
  Object.entries(b).forEach(([key, value]) => {
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
  });
  return newSearchParams;
}

export function makeParamsLink(
  pathname: string,
  currentSearchParams: string = window.location.search,
  newParams?: Record<string, string | null | undefined>,
) {
  const existingParams = new URLSearchParams(currentSearchParams);

  return Nav.makeTo({
    pathname,
    search:
      newParams && Object.keys(newParams).length > 0
        ? mergeSearchParams(existingParams, newParams).toString()
        : currentSearchParams,
  });
}

export function makeDrawerLink<T extends string>(
  currentLocation: PathAndSearch,
  path: T,
  args: ExtractRouteParams<T, string>,
  queryParams?: Record<string, string | null | undefined>,
) {
  const drawerPath = queryParams
    ? makeParamsLink(makeLink(path, args), '', queryParams)
    : makeLink(path, args);

  return Nav.makeTo({
    root: {
      pathname: currentLocation.pathname,
      search: currentLocation.search,
    },
    drawer: drawerPath,
  });
}

export function makeDialogLink<T extends string>(
  currentLocation: PathAndSearch,
  path: T,
  args: ExtractRouteParams<T, string>,
  queryParams?: Record<string, string | null | undefined>,
) {
  const dialogPath = queryParams
    ? makeParamsLink(makeLink(path, args), '', queryParams)
    : makeLink(path, args);

  return Nav.makeTo({
    root: {
      pathname: currentLocation.pathname,
      search: currentLocation.search,
    },
    dialog: dialogPath,
  });
}
